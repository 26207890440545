@import url('https://fonts.googleapis.com/css2?family=Open+Sans');

@font-face {
  font-family: 'futura_ltregular';
  src: url('assets/fonts/futuralt-webfont.woff2') format('woff2'), url('assets/fonts/futuralt-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'futura_ltbold';
  src: url('assets/fonts/futuralt-bold-webfont.woff2') format('woff2'), url('assets/fonts/futuralt-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'futura_ltcondensed';
  src: url('assets/fonts/futuralt-condensed-webfont.woff2') format('woff2'), url('assets/fonts/futuralt-condensed-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'futura_ltheavy';
  src: url('assets/fonts/futuralt-heavy-webfont.woff2') format('woff2'), url('assets/fonts/futuralt-heavy-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}