.section_Genealogy {
  margin-top: 20px!important;
  
  .main_title {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 1199px) {
  .section_Genealogy {
    margin-top: 60px!important;

    .main_title {
      margin-bottom: 20px;
    }

    .vc_col-sm-8 {
      width: 60%;
    }

    .vc_col-sm-4 {
      width: 40%;
    }
  }

}

@media screen and (max-width: 992px) {
  .section_Genealogy {
    .vc_col-sm-8 {
      width: 50%;
    }

    .vc_col-sm-4 {
      width: 50%;
    }
  }
}

@media screen and (max-width: 767px) {
  .section_Genealogy {
    .vc_col-sm-8, .vc_col-sm-4 {
      width: 100%;
    }

    .vc_col-sm-4 {
      padding-top: 30px;
    }
  }
}

@media (min-width: 1200px) {
  .section_Genealogy {
    margin-top: 100px!important;
  }
}