.page-template-page-blog, .single-post {
  .wp-block-tag-cloud {
    a {
      border: 2px solid #fff;
      background: #F5F5F5;
      color: #666666;

      &:hover {
        border: 2px solid #F2F2F2;
        -webkit-box-shadow: 0 0 0 1px rgba(221,221,221,0);
        box-shadow: 0 0 0 1px rgba(221,221,221,0);
      }
    }
  }
}


.widget {
  &.widget_tag_cloud {
    .wp-block-tag-cloud {
      display: flex;
      flex-wrap: wrap;

      a {
        display: flex!important;
        align-items: center;
        flex-direction: row;
        border: 2px solid #fff;
        background: #F5F5F5;

        span {
          margin-left: 2px;
        }

        &:hover {
          border: 2px solid #F2F2F2;
          -webkit-box-shadow: 0 0 0 1px rgba(221,221,221,0);
          box-shadow: 0 0 0 1px rgba(221,221,221,0);
        }
      }
    }
  }
}