body:not(.twentyseventeen-front-page) .entry-header {
  padding: 0;
}
.panel-content .entry-header {
  margin-bottom: 0;
}

.page-template-glossary {
  h1 {
    font-family: 'Open Sans' !important;
    font-style: normal;
    font-weight: 700 !important;
    font-size: 48px !important;
    line-height: 65px !important;
    color: #333333 !important;
    margin-bottom: 21px;
  }
  p {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    color: #515151;
    padding-bottom: 20px;
  }
  .btn_common {
    a {
      width: 368px;
      height: 45px;
      background: #43A047;
      border-radius: 3px;
      text-align: left;
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: #FFFFFF;
      position: relative;
      padding: 12px 0 0 25px;
      text-decoration: none;
      text-transform: capitalize;
      &:before {
        content: '';
        background: url(assets/images/new-icons/arrow.png) no-repeat;
        position: absolute;
        right: 25px;
        top: 12px;
        width: 20px;
        height: 100%;
        background-position: top;
        z-index: 100;
      }
      &:hover {
        background: transparent !important;
        border: 1px solid #43A047 !important;;
        border-radius: 3px;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        text-transform: capitalize;
        color: #333333 !important;
        text-decoration: none;
        &:before {
          background: url(assets/images/new-icons/arrow_hover.png) no-repeat;
        }
      }
    }
  }
  .row-content {
    position: relative;
    margin-bottom: 21px;
    display: block;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    color: #515151;
    overflow: hidden;
    .col-1-main {
      position: relative;
      width: 270px;
      float: left;
      text-align: left;
    }
    .col-2-text {
      position: relative;
      float: left;
      width: calc((100%) - 270px);
      text-align: left;
    }
  }
  
  @media (max-width: 480px) {
    .row-content {
      .col-1-main {
        width: 100%;
        float: unset;
      }
      .col-2-text {
        float: unset;
        width: 100%;
      }
    }
    
  }

}

@media (min-width: 360px) and (max-width: 399px) {
  .page-template-glossary .btn_common a {
    width: 340px;
    padding: 12px 0 0 10px;
  }
  .page-template-glossary .btn_common a:before {
    right: 10px;
  }
}