.main_title h1 {
  font-size: 34px;
  color: #061d08;
  font-family: 'futura_ltbold';
  padding: 0;
  margin: 0;
  line-height: normal;
  margin-top: 10px;
}

.breadcrumbs .left-breadcrumbs h1 {
  font-size: 36px;
  color: #fff;
  font-family: 'futura_ltbold';
}

.treemly_home.home_slider .flex-control-nav.flex-control-paging a:focus {
  box-shadow: none;
}

.search-form .search-submit {
  background: transparent;
}

.search-form .search-submit:hover, .search-form .search-submit:focus {
  background: transparent;
}

.search-form .search-submit .icon {
  height: 19px;
  top: 7px;
  width: 20px;
}

.vc_btn3.vc_btn3-color-green.vc_btn3-style-modern {
  //background: #005d34!important;
  //color: #fff;
  //font-size: 18px;
  //font-weight: 600;
  //font-family: 'Open Sans', sans-serif;
  //padding: 16px 40px;
  //display: inline-block;
  //border: 2px solid transparent!important;
  //text-decoration: none;
  //box-shadow: none !important;
  //transition: 0.3s;
  //border-radius: 0;
}

.vc_btn3.vc_btn3-color-green.vc_btn3-style-modern:hover {
  //color: #005d34!important;
  //border-color: #005d34!important;
  //background: transparent!important;
  background: #3B8E3F;
  border: 1px solid #43A047 !important;;
  border-radius: 3px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  text-transform: capitalize;
  //color: #333333 !important;
  //&:before {
  //  background: url(assets/images/new-icons/arrow_hover.png) no-repeat;
  //}
}

.wpcf7 {
  font-size: 1.4rem;

  span {
    font-size: 1.4rem
  }

  input {
    font-size: 1.4rem;
  }

  input[type=submit] {
    font-size: 1.4rem;
  }

  .wpcf7-not-valid-tip {
    background: #ffffff;
    border: 1px solid #dc3232;
  }

  .wpcf7-response-output {
    background: #ffffff;
  }
}

.widget-area {
  h2, a {
    font-size: 1.4rem;
  }
}

.archive, .search {
  .widget {
    padding-bottom: 5px;

    &.widget_categories {
      .widget-title {
        margin-bottom: 5px;
      }
    }
  }

  .widget-title {
    margin-bottom: 10px;
  }
}

//.vc_btn3.vc_btn3-color-green.vc_btn3-style-modern {
//    width: 200px;
//    height: 45px;
//    background: #43A047;
//    border-radius: 3px;
//    font-family: 'Open Sans';
//    font-style: normal;
//    font-weight: 600;
//    font-size: 14px;
//    line-height: 19px;
//    align-items: center;
//    text-align: center;
//    text-transform: capitalize;
//    color: #FFFFFF;
//}


@media (min-width: 768px) {
  .mailchimp-main-wrap {
    background-image: url("assets/images/newsletter-banner.jpg") !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
  }
}

@media screen and (max-width: 767px) {

}

.comment-form .form-submit #submit:hover,
#searchform .custom-form #searchsubmit:hover {
  background-color: #3B8E3F;
}

.comment-form .form-submit #submit {
  color: #f7f7f7;
  border-color: #5f9434;
  border: 1px solid transparent;
  text-decoration: none;
  font-size: 14px;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 20px;
  padding-right: 20px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  line-height: 14px !important;
  text-transform: capitalize;
  background-color: #43A047;
}
