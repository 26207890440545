h2.mch-h2 {
  padding-bottom: 20px;
}

p.mch-p {
  padding-bottom: 25px;
}

.footer-mailchimp {
  .mainchimp-form {
    input[type="submit"] {
      font-family: 'Open Sans', sans-serif;
      font-weight: 600;

      &:hover {
        background: #3B8E3F;
      }
    }
    
    input[type="email"] {
      font-family: "Open Sans", sans-serif;
      font-weight: 600;
      line-height: 24px;
      font-size: 1.4rem;
      color: #333333 !important;
    }
  }

  .mailing-section-block {
    padding: 0 !important;
  }
}

#wpcf7-f1947-o1 {
  ::placeholder {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    line-height: 24px;
    font-size: 1.4rem;
    color: #aaa !important;
    opacity: 1;

  }
  ::-webkit-input-placeholder {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    line-height: 24px;
    font-size: 1.4rem;
    color: #aaa !important;
    opacity: 1;
  }
  :-moz-placeholder {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    line-height: 24px;
    font-size: 1.4rem;
    color: #aaa !important;
    opacity: 1;
  }
  :-ms-input-placeholder {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    line-height: 24px;
    font-size: 1.4rem;
    color: #aaa !important;
    opacity: 1;
  }
}

.wpcf7 form .wpcf7-response-output {
  background: transparent;
  color: red;
  border: none;
  padding: 0;
  margin: 0;
}

.wpcf7 .wpcf7-not-valid-tip {
  border: none;
  background: transparent;
  float: left;
  padding-top: 5px;
}

.bg-mch-color {
  background: #f8f7f7;
  background-image: url(/wp-content/uploads/2022/04/Intersect.png) !important;
  background-position-x: -12%;
  background-position-y: -3%;
  background-size: 1280px !important;
  background-repeat: no-repeat !important;
}

.wpcf7-response-output {
  margin: 2em 0em 0em !important;
}
.wpcf7-spinner {
  float: left !important;
}
