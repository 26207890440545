.wrap, .navigation-top .wrap {
  max-width: 100%;
  width: 100%;
  padding-right: 0;
  padding-left: 0;
}

body:not(.home) .wrap {
  max-width: 1340px;
}

@media screen and (max-width: 1400px) {
  .main_container {
    max-width: 1170px;
  }

  body:not(.home) .wrap {
    max-width: 1170px;
  }

  .main_title h2 {
    font-size: 28px;
  }

  .main_title span {
    font-size: 18px;
  }

  .treemly_info ul li {
    font-size: 18px;
    margin-bottom: 24px;
  }

  .title_how_it_work p {
    font-size: 18px;
    line-height: 22px;
  }

  .how_it_work .vc_col-sm-3 > .vc_column-inner > .wpb_wrapper {
    min-height: 180px;
  }

  .btn_common a {
    font-size: 16px;
    padding: 12px 20px;
  }

  .semi-title h2 {
    font-size: 24px;
  }

  .flat_content p {
    font-size: 16px;
    line-height: 28px;
  }

  .column_info p {
    font-size: 16px;
    line-height: 28px;
  }

  .offer_title_main h2 {
    font-size: 28px;
  }

  .order_point_title p {
    font-size: 16px;
    line-height: 20px;
  }

  .order_boxes > .wpb_column > .vc_column-inner > .wpb_wrapper {
    min-height: 210px;
  }

  .we_re_diffrent ul li {
    font-size: 16px;
  }

  #hs_testimonial_theme_1 .hs_client_message {
    font-size: 16px !important;
  }

  .profile_boxes_wrapper .profile_info h3 {
    font-size: 26px;
  }

  .profile_boxes_wrapper .profile_info h4 {
    font-size: 18px;
  }

  .profile_boxes_wrapper .vc_column-inner > .wpb_wrapper {
    min-height: 220px;
  }

  .site-footer .widget-area .widget-column h2.widget-title {
    font-size: 16px;
  }

  .site-footer .widget_nav_menu a {
    font-size: 14px;
  }

  .site-footer .widget_nav_menu li {
    margin-bottom: 16px;
  }

  .offer_zone:before {
    height: 290px;
    top: 72%;
    left: -170px;
  }

  .img_dot:before {
    right: -52px;
  }

  .gray-plan .col-sm-4 {
    padding: 21px 10px;
  }

  .comparison .text_light {
    font-size: 13px;
  }

  .page_about_us {
    min-height: 380px;
  }

  .breadcrumbs .left-breadcrumbs {
    font-size: 30px;
  }

  .breadcrumbs {
    height: 120px;
  }

  .gray-plan .col-sm-4 {
    font-size: 14px;
  }

  .single-post .single-featured-image-header {
    max-height: 400px;
  }

  .site-header .main-navigation li.menu-item-1127 {
    padding-right: 0;
  }

  .tools_accordian .evdpl-featured_tool-accordion-wrap.ui-accordion .ui-accordion-content {
    padding: 40px 20px;
  }

  .plan_info_title {
    padding-right: 8px;
  }
  //.plan_icon {
  //  padding-right: 35px;
  //}
}

@media screen and (max-width: 1199px) {
  body:not(.home) .wrap {
    max-width: 920px;
  }

  .treemly_info ul li {
    font-size: 16px;
    margin-bottom: 22px;
  }

  .how_it_work {
    margin-top: 30px !important;
  }

  .chart_column {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }

  .offer_zone {
    padding-top: 20px;
  }

  .offer_title_main h2 {
    font-size: 24px;
  }

  .order_boxes .order_point_title {
    padding: 0 10px;
  }

  .offer_zone:before {
    height: 230px;
  }

  .bussiness_company {
    margin-top: 60px !important;
  }

  .home_testimonials {
    margin-top: 60px;
  }

  .main_container {
    max-width: 920px;
  }

  .flat_content {
    padding-left: 30px !important;
    padding-right: 40px !important;
  }

  .site-footer .widget-area .widget-column.f_guaranteed {
    width: 35%;
  }

  .site-footer .widget-area .widget-column.f_tools {
    width: 20%;
  }

  .site-footer .widget-area .widget-column.f_quick_links {
    width: 20%;
  }

  .comparison tr td:first-child {
    font-size: 14px;
  }

  .comparison .text_light {
    font-size: 14px;
  }

  .tab_box a {
    font-size: 16px;
  }

  .gray-plan .col-sm-4 span:before {
    top: 2px;
  }

  .gray-plan .col-sm-4 {
    font-size: 16px;
    padding: 26px 10px;
  }

  .site-header .main-navigation li.menu-item-1127 {
    padding-right: 0;
  }

  .about_description {
    max-width: 70%;
  }

  .site-header .main-navigation li.menu-item-1127 a {
    padding-right: 30px;
    padding-left: 40px;
  }

  .treemily-banners {
    height: 340px;
  }

  .page-id-1411 .treemily-banners {
    height: 360px;
  }

  .page_info_inner p {
    font-size: 16px;
  }

  .site-footer .widget-area .widget-column section#custom_html-2 {
    text-align: center;
  }

  .main_offer_block ul li {
    //flex-basis: 47%;
    //max-width: 47%;
    margin-bottom: 3% !important;
  }

  .main_offer_block ul li {
    flex-basis: 175px;
  }

  .plan_shape {
    width: 176px;
  }

  .plan_info_title {
    padding-right: 15px;
  }

  //.plan_icon {
  //  padding-right: 45px;
  //}

  //.site-header .main-navigation .menu > li a {
  //  font-size: 14px;
  //}

  .site-header .main-navigation li.menu-item-1127 a:after {
    background-position: -92px -14px;
  }

  //.navigation-top .custom-logo-link {
  //  /*width: 160px;*/
  //  width: 133px;
  //}

}

@media screen and (max-width: 992px) {
  .treemily-banners[class*="vc_custom_"] {
    background-position: 64% 0 !important;
  }

  body:not(.home) .wrap {
    max-width: 730px;
  }

  .main_container {
    max-width: 730px;
  }

  .treemly_right {
    padding-left: 0px !important;
  }

  .title_how_it_work p {
    font-size: 16px;
    line-height: 22px;
  }

  .title_how_it_work {
    margin-top: 22px !important;
  }

  .offer_title_main h2 {
    font-size: 20px;
  }

  .order_point_title p {
    font-size: 14px;
    line-height: 18px;
  }

  .we_re_diffrent ul {
    padding-left: 0;
  }

  .we_re_diffrent ul li {
    font-size: 14px;
    padding-left: 40px;
    margin-bottom: 12px;
  }

  .we_re_diffrent ul li:before {
    left: 10px;
  }

  .home_testimonials .hs_testimonial_wrapper {
    max-width: 100%;
  }

  .profile_boxes_wrapper .profile_info h3 {
    font-size: 20px;
  }

  .profile_boxes_wrapper .profile_info h4 {
    font-size: 16px;
    margin-top: 0;
  }

  .profile_boxes_wrapper .vc_column-inner > .wpb_wrapper {
    min-height: 180px;
  }

  .order_boxes > .wpb_column > .vc_column-inner > .wpb_wrapper {
    min-height: 180px;
  }

  .flat_content {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .panel-content .wrap {
    padding-bottom: 40px;
  }

  section#custom_html-2 a:before {
    left: 10px;
    top: 9px;
  }

  section#custom_html-2 a {
    padding-left: 35px !important;
    padding: 8px 13px;
  }

  .page_description {
    max-width: 100%;
  }

  .comparison .price-buy {
    padding: 4px 20px;
    font-size: 18px;
    min-width: 140px;
  }

  .comparison td, .comparison th {
    padding: 10px 10px;
  }

  .comparison tr td:first-child {
    padding-left: 10px;
  }

  .comparison thead tr th.price-info .price-title {
    font-size: 22px;
  }

  .tab_box a {
    font-size: 12px;
    padding: 9px 10px;
  }

  .post_content .main_title h2 br {
    display: none;
  }

  .post_content .main_title h2 {
    line-height: 28px;
    font-size: 24px;
  }

  .post_content .main_title p {
    font-size: 16px;
  }

  .tools_accordian .main_title {
    margin-bottom: 30px;
  }

  .tools_accordian .main_title h2 {
    font-size: 24px;
  }

  .tools_accordian .evdpl-featured_tool-accordion-wrap.ui-accordion .ui-accordion-content {
    padding: 20px;
  }

  .gray-plan {
    margin-top: 30px;
  }

  .gray-plan .col-sm-4 {
    font-size: 12px;
    padding: 19px 10px;
  }

  .gray-plan .col-sm-4 span {
    padding-left: 21px;
  }

  .gray-plan .col-sm-4 span:before {
    height: 14px;
    width: 14px;
  }

  .evdpl-featured_tool-accordion-content .col-sm-8 {
    width: 60%;
  }

  .evdpl-featured_tool-accordion-content .col-sm-4 {
    width: 40%;
  }

  .tools_accordian {
    margin-top: 20px !important;
  }

  .site-header .navigation-top {
    padding-left: 20px;
  }

  .navigation-top .custom-logo-link {
    /*width: 160px;*/
    //width: 133px;
  }

  //.navigation-top .main-navigation {
  //  /*width: calc(100% - 160px);*/
  //  width: calc(100% - 135px);
  //}

  .page_info_inner h1 {
    font-size: 26px;
  }

  .page_info_inner p {
    font-size: 16px;
  }

  .page_info_inner {
    max-width: 90%;
  }

  .tools_accordian .evdpl-featured_tool-accordion-wrap.ui-accordion .ui-accordion-content p {
    font-size: 14px;
  }

  .tools_accordian .evdpl-featured_tool-accordion-wrap.ui-accordion .ui-accordion-icons {
    padding-left: 20px;
    font-size: 14px;
    padding: 14px 10px 14px 10px;
  }

  .section_order_printing.accordian_page {
    margin-top: 20px !important;
  }

  .tab_content {
    margin-top: 10px !important;
  }

  .post_grid {
    margin-top: 0;
  }

  .page_boxes_points {
    padding-top: 20px;
    margin-top: 20px;
  }

  .page_boxes_points ul li {
    margin-bottom: 14px;
    padding-left: 25px;
    font-size: 16px;
  }

  .page_boxes_points ul li:before {
    width: 18px;
    height: 18px;
    top: 4px;
  }

  //.site-footer .widget_nav_menu a {
  //  font-size: 13px;
  //}
  //
  //.site-footer .widget_nav_menu li {
  //  margin-bottom: 10px;
  //}
  //
  //.site-footer .widget_nav_menu a:hover {
  //  padding-left: 20px;
  //}

  .site-header .main-navigation li.menu-item-1127 a {
    padding-right: 30px;
  }

  //.site-header .main-navigation .menu > li {
  //  padding-right: 2px;
  //  padding-left: 2px;
  //}
  //
  //.site-header .main-navigation .menu > li a {
  //  font-size: 14px;
  //  padding-right: 3px;
  //}

  .site-header .main-navigation li.menu-item-1127 {
    padding-right: 0;
  }

  .breadcrumbs .left-breadcrumbs {
    font-size: 24px;
  }

  .breadcrumbs {
    height: 90px;
  }

  .breadcrumbs > .main_container {
    padding: 10px 10px;
  }

  .page-id-1110 .site-content-contain {
    overflow: hidden;
  }

  .page_faq .evdpl-faq-accordion-wrap.ui-accordion .ui-accordion-icons {
    padding: 13px 33px;
    padding-left: 10px;
  }

  .page_faq .evdpl-faq-accordion-wrap.ui-accordion .ui-accordion-header .ui-accordion-header-icon {
    right: 5px;
  }

  .page_faq .evdpl-faq-accordion-wrap.ui-accordion .ui-accordion-icons {
    font-size: 14px;
  }

  .treemily-banners .page_info_inner p {
    text-shadow: 1px 1px 1px #000, 3px 3px 5px #000;
  }

  //.plan_icon {
  //  padding-right: 35px;
  //}
}

@media screen and (max-width: 767px) {
  .site-footer .widget-area .widget-column section#custom_html-2 {
    text-align: center;
  }

  .treemily-banners:before {
    content: '';
    display: block !important;
    background: rgba(0, 0, 0, 0.4);
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .treemily-banners[class*="vc_custom_"] {
    position: relative;
    background-position: 76% 0 !important;
  }

  body:not(.home) .wrap {
    max-width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }

  body {
    padding-top: 0px;
  }

  .site-header .navigation-top {
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
  }

  .home_slider {
    padding-left: 0;
  }

  .menu-toggle {
    outline: none !important;
    float: right;
    padding-right: 0;
    padding: 1.2em 1.5em ;
  }

  .how_it_work .vc_col-sm-3 {
    max-width: 50%;
    clear: none;
    display: inline-block;
    float: left;
    margin-bottom: 30px;
    margin-bottom: 20px;
  }

  .how_it_work .btn_common {
    margin-top: 10px;
  }

  .offer_boxes .vc_col-sm-4 {
    float: left;
    width: 33%;
  }

  .order_boxes [class*="vc_col-sm-1"] {
    width: 33%;
    display: inline-block;
    margin-bottom: 20px;
    vertical-align: top;
  }

  .we_re_diffrent {
    margin: 0 auto;
  }

  .home_testimonials div#hs_testimonial_theme_1 {
    padding: 0 40px;
    padding-top: 20px;
  }

  .profile_boxes_wrapper .vc_col-sm-3 {
    width: 33%;
    display: inline-block;
    margin-bottom: 20px;
  }

  .treemly_img {
    margin-top: 20px;
  }

  .treemly_info ul li {
    width: 50%;
    float: left;
  }

  .wpb_button, .wpb_content_element, ul.wpb_thumbnails-fluid > li {
    margin-bottom: 20px !important;
  }

  .we_re_diffrent {
    padding-top: 20px;
  }

  .home_testimonials .hs_testimonial_wrapper {
    max-width: 90%;
  }

  .section_profile {
    margin-top: 50px !important;
  }

  .bussiness_company {
    margin-top: 10px !important;
  }

  .bussiness_listing {
    margin-top: 0;
  }

  .treemly_right .main_title {
    text-align: center;
  }

  aside.widget-area {
    padding: 0 20px;
  }

  .site-footer .widget-area .widget-column {
    width: 50%;
    float: left;
    margin-bottom: 20px;
  }

  .footer_copyright {
    padding-top: 9px;
    padding: 9px 20px;
  }

  .geneology_tools:before {
    height: 100%;
  }

  .chart_img:before {
    height: 100%;
  }

  .flat_content {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .home_testimonials {
    margin-top: 30px;
  }

  .treemly_info ul li {
    padding-left: 38px;
  }

  .treemly_info ul li {
    font-size: 14px;
    margin-bottom: 15px;
  }

  .main_title h2 {
    font-size: 24px;
    margin-top: 2px;
  }

  .offer_title p {
    font-size: 16px;
    line-height: 20px;
  }

  .order_boxes .order_point_title {
    padding-bottom: 0;
    margin-bottom: 0 !important;
  }

  .home_testimonials div#hs_testimonial_theme_1 {
    padding: 0 30px;
    padding-top: 20px;
  }

  .main_title span {
    font-size: 16px;
  }

  .column_info .btn_common {
    margin-top: 20px;
    margin-bottom: 40px !important;
  }

  .geneology_tools .btn_common {
    margin-top: 20px;
  }

  .profile_boxes_wrapper .vc_column-inner > .wpb_wrapper {
    padding: 13px;
  }

  .title_how_it_work {
    margin-top: 5px !important;
  }

  //.main-navigation .menu-main-menu-container {
  //  width: 100%;
  //  margin-top: 64px;
  //  position: relative;
  //  z-index: 2;
  //}
  //
  //.navigation-top .main-navigation {
  //  width: 100vw;
  //  float: right;
  //  display: inline-block;
  //  clear: none;
  //  text-align: right;
  //  position: absolute;
  //  top: 0px;
  //  //left: -20px;
  //  right: 0;
  //}

  .navigation-top .custom-logo-link {
    position: relative;
    z-index: 2;
  }

  //.site-header .main-navigation li {
  //  padding-top: 0;
  //  height: auto;
  //  border-bottom: 1px solid #005d34;
  //}

  .img_dot:before {
    width: 190px;
    height: 115px;
    right: 11px;
  }

  .offer_zone {
    margin-bottom: 20px;
  }

  .chart_column {
    margin-top: 20px !important;
  }

  //.main-navigation > div > ul {
  //  background: #e4e4e4;
  //  padding: 0;
  //  padding-bottom: 10px;
  //}
  //
  //.site-header .main-navigation li.current-menu-item a:before {
  //  margin-right: 0;
  //  margin-left: auto;
  //  visibility: hidden;
  //}

  .home_slider .wpb_content_element {
    margin-bottom: 0 !important;
  }

  .treemly_img:before {
    height: 70px;
    visibility: hidden;
  }

  .navigation-top .current-menu-item > a, .navigation-top .current_page_item > a {
    color: #005d34;
  }

  .site-footer:before {
    width: 140px;
    height: 150px;
  }

  .home .flexslider {
    margin: 0 0 30px;
  }

  .how_it_work .vc_col-sm-3 > .vc_column-inner {
    padding: 13px;
  }

  .how_it_work .vc_col-sm-3 > .vc_column-inner > .wpb_wrapper {
    min-height: 160px;
  }

  .site-footer .widget-area .widget-column.f_logo {
    width: 40%;
  }

  .site-footer .widget-area .widget-column.f_guaranteed {
    width: 60%;
  }

  .site-footer .widget-area .widget-column.f_tools {
    width: 60%;
  }

  .site-footer .widget-area .widget-column.f_quick_links {
    width: 40%;
  }

  .site-footer .widget-area {
    padding-top: 0;
    padding-bottom: 0;
  }

  .site-footer .widget_text ul {
    padding-bottom: 10px;
  }

  .f_logo p {
    margin-bottom: 0;
  }

  .order_boxes .vc_column_container > .vc_column-inner {
    padding-right: 10px;
    padding-left: 10px;
  }

  .profile_boxes_wrapper .vc_column_container > .vc_column-inner {
    padding-left: 10px;
    padding-right: 10px;
  }

  .profile_boxes_wrapper {
    padding: 0 10px;
  }

  #hs_testimonial_theme_1 .hs_client_message {
    font-size: 14px !important;
  }

  .page_faq .right-cate-faq {
    padding-left: 0;
    width: 100%;
  }

  .page_faq .left-cate-faq {
    width: 440px;
    float: none;
    margin: 0 auto;
    margin-bottom: 30px;
  }

  .page_faq .evdpl-faq-accordion-wrap.ui-accordion .ui-accordion-content p {
    font-size: 14px;
  }

  .page_faq .evdpl-faq-accordion-wrap.ui-accordion .ui-accordion-icons {
    padding: 12px 35px;
    padding-left: 15px;
    margin-bottom: 8px;
  }

  .page_faq .evdpl-faq-accordion-wrap.ui-accordion .ui-accordion-content {
    padding: 10px;
  }

  .left-cate-faq a {
    padding-left: 15px;
    font-size: 16px;
  }

  .page_description h3 {
    font-size: 22px;
  }

  //.site-header .main-navigation li:last-child a:after {
  //  left: 36px;
  //}

  .page_faq .page_description h3 {
    font-size: 18px;
  }

  .page_faq .page_description p {
    font-size: 14px;
  }

  .left-cate-faq .evdpl-faq-term-title {
    padding: 12px 0;
  }

  .page:not(.home) #content {
    margin-top: 0;
  }

  .dot_how_it_works:before {
    visibility: hidden;
  }

  .comparison thead tr th.price-info {
    height: 60px;
  }

  .comparison thead tr th.price-info .price-title {
    font-size: 18px;
  }

  .comparison td, .comparison th {
    padding: 6px 3px;
    color: #061d08;
    font-size: 12px;
    /*font-family: 'open_sanssemibold';*/
    font-family: 'Open Sans', sans-serif;
  }

  .comparison .text_light {
    font-size: 12px;
    line-height: 14px;
  }

  .comparison .price-info {
    border: 0;
  }

  .page_info_inner {
    max-width: 100%;
  }

  .post_grid .vc_col-sm-4, .post_grid .vc_col-sm-8 {
    width: 100%;
  }

  .evdpl-featured_tool-accordion-content .col-sm-8,
  .evdpl-featured_tool-accordion-content .col-sm-4 {
    width: 100%;
    padding: 0;
  }

  .gray-plan {
    margin-bottom: 20px !important;
    margin-top: 20px;
  }

  .tools_accordian .main_title h2 {
    font-size: 18px;
  }

  .tab_box ul {
    flex-direction: column;
  }

  .tab_box ul li {
    width: 100%;
    padding-right: 0;
  }

  .tab_box a {
    border-radius: 8px;
  }

  .tab_box {
    max-width: 500px;
    margin: 0 auto;
  }

  .gray-plan .col-sm-4 {
    padding: 19px 3px !important;
  }

  .evdpl-featured_tool-accordion-content .col-sm-8.image-Right {
    padding-left: 0;
  }

  .gray-plan .col-sm-4:nth-child(1) {
    flex-basis: 45%;
  }

  .tab_content .vc_column-inner {
    padding: 0 10px;
  }

  .tools_accordian .vc_column_container > .vc_column-inner {
    padding: 0 10px;
  }

  .tools_accordian .evdpl-featured_tool-accordion-wrap.ui-accordion .ui-accordion-header .ui-accordion-header-icon {
    top: 43%;
    right: 4px;
  }

  .tools_accordian .evdpl-featured_tool-accordion-wrap .ui-icon-triangle-1-e {
    //background-image: url(../../../assets/images/accordian_plus.png) !important;
    width: 14px;
    height: 14px;
  }

  .tools_accordian .evdpl-featured_tool-accordion-wrap .ui-icon-triangle-1-s {
    width: 14px;
    height: 2px;
  }

  .tools_accordian .evdpl-featured_tool-accordion-wrap.ui-accordion .ui-accordion-content {
    padding: 10px;
  }

  .page_info_inner p {
    font-size: 14px;
  }

  .post_content .main_title h2 {
    line-height: 26px;
    font-size: 22px;
  }

  .post_content .main_title p {
    font-size: 14px;
  }

  .page_boxes_points ul li {
    font-size: 14px;
  }

  .site-footer aside.widget-area {
    padding: 0 5px;
  }

  .site-footer .widget ul {
    margin-top: 16px;
  }

  /*main menu*/
  //.site-header .main-navigation .menu > li {
  //  height: auto;
  //  padding: 0;
  //  display: inline-block;
  //  width: 100%;
  //}
  //
  //.site-header .main-navigation .menu > li a {
  //  padding-right: 20px
  //}
  //
  ///*Sub menu*/
  //.main-navigation ul ul.sub-menu {
  //  position: relative;
  //  z-index: 1;
  //  padding: 0 15px;
  //  width: 100%;
  //  right: 0;
  //  float: right;
  //  text-align: right;
  //}
  //
  //.site-header .main-navigation .menu .sub-menu > li:last-child {
  //  border-bottom: 0;
  //}
  //
  //.main-navigation ul ul.sub-menu a {
  //  padding-right: 26px !important;
  //}

  .about_description h1 {
    font-size: 24px;
    line-height: 26px;
  }

  .about_description p {
    font-size: 16px;
    line-height: 22px;
  }

  .about_description {
    max-width: 100%;
  }

  .about_description p {
    font-size: 14px;
    line-height: 20px;
  }

  .page-id-1446 .vc_column_container > .vc_column-inner {
    padding-left: 5px;
    padding-right: 5px;
  }

  .page-id-1446 .how_it_work {
    margin-top: 0px !important;
  }

  .page-id-1446 .how_it_work .btn_common {
    margin-right: -5px;
    margin-left: -5px;
  }

  //.site-header .main-navigation .menu > li.menu-item-has-children a {
  //  padding-right: 40px;
  //}
  //
  //.site-header .main-navigation .menu > li.menu-item-has-children .dropdown-toggle {
  //  top: 4px;
  //  right: 10px;
  //  outline: none;
  //}

  .site-header .main-navigation li.menu-item-1127 {
    text-align: center;
    margin: 0 auto;
    padding: 5px 0;
  }

  .site-header .main-navigation li.menu-item-1127 a {
    border-radius: 6px;
    max-width: 193px;
    padding-left: 0;
    display: inline-block;
    padding: 8px 40px;
    padding-left: 60px;
  }

  //.site-header .main-navigation li:last-child a:after {
  //  left: 33px;
  //}
  //
  //.main-navigation ul ul.sub-menu .current-menu-item a {
  //  color: #04633b;
  //}
  //
  //body .site-header .main-navigation .menu .sub-menu .current-menu-item a {
  //  color: #04633b !important;
  //}

  .site-footer .social_icon ul {
    margin: 0;
    padding: 0;
  }

  body.page:not(.twentyseventeen-front-page) .entry-title {
    font-size: 26px;
    margin-top: 8px;
  }

  .radius_top_left {
    border-top-left-radius: 15px;
  }

  .breadcrumbs > .main_container {
    padding: 0 10px;
  }

  .breadcrumbs .left-breadcrumbs {
    font-size: 18px;
    line-height: 20px;
  }

  .page_faq .left-cate-faq > [class*="col-"] .col-sm-12 h3 a {
    margin-left: 40px;
  }

  .main_offer_block ul li {
    flex-basis: 31%;
    max-width: 220px;
  }

  .services-link ul li {
    width: 40%;
  }

  .footer-mailchimp .mailing-section-block {
    padding: 2rem;
  }

  .footer-mailchimp .mailing-section-block p {
    font-size: 16px;
  }

  .footer-mailchimp .mainchimp-form .wpcf7-form-control {
    font-size: 16px;
  }

  .custom_btn_wrapper .vc_general {
    max-width: 300px;
  }

}

@media screen and (max-width: 680px) {
  .footer_copyright .main_container {
    display: flex;
    flex-direction: column;
  }

  .footer_copyright .copyrighy_info {
    flex-basis: 100%;
    text-align: center;
    width: 100%;
    order: 2;
  }

  .footer_copyright .social_icon {
    flex-basis: 100%;
    width: 100%;
  }

  .social_icon ul {
    text-align: center;
  }

  .offer_zone .vc_column-inner {
    padding-top: 0 !important;
  }

  .offer_zone {
    padding-bottom: 10px;
  }

  .offer_btn {
    bottom: -45px;
  }

  .offer_zone:before {
    height: 150px;
    top: 84%;
    width: 295px;
  }

  .comparison .price-buy {
    min-width: auto;
  }

  .comparison .text-year {
    margin-bottom: 5px !important;
  }

  .tools_accordian .evdpl-featured_tool-accordion-wrap.ui-accordion .ui-accordion-icons {
    padding: 14px 28px 14px 10px;
  }

  .main_offer_block ul li {
    flex-basis: 48%;
    max-width: 220px;
    margin-bottom: 3% !important;
  }

  .main_offer_block ul li:nth-child(2) {
    margin-right: 0;
  }

  .services-link ul li {
    width: 100%;
  }
}
@media screen and (max-width: 480px) {
  .treemly_info ul li {
    width: 100%;
    padding-left: 26px;
  }

  .treemly_info ul li:before {
    width: 20px;
    height: 20px;
    background-size: 15rem;
    top: 2px;
    background-position: -4px -4px;
  }

  .btn_common a {
    font-size: 14px;
    padding: 12px 10px;
  }

  .semi-title h2 {
    font-size: 22px;
  }

  .offer_boxes .wpb_single_image .vc_single_image-wrapper:before {
    width: 45px;
    height: 45px;
  }

  .offer_title p {
    line-height: 22px;
  }

  .section_order_printing {
    margin-top: 30px !important;
  }

  .order_boxes [class*="vc_col-sm-1"] {
    width: 50%;
  }

  .bussiness_img:before {
    height: 85px;
    left: -63px;
  }

  .home_testimonials {
    margin-top: 20px;
  }

  .home_testimonials .hs_testimonial_wrapper {
    max-width: 100%;
  }

  .home_testimonials div#hs_testimonial_theme_1 {
    padding: 0;
    padding-top: 15px;
  }

  .home_testimonials .owl-theme .owl-nav {
    position: static;
    transform: translate(0%, -50%);
    margin-top: 20px;
  }

  .home_testimonials .owl-theme .owl-nav .owl-prev,
  .home_testimonials .owl-theme .owl-nav .owl-next {
    position: static;
    left: 0;
    right: 0;
  }

  .profile_boxes_wrapper .vc_col-sm-3 {
    width: 50%;
    margin-bottom: 20px;
  }

  .profile_boxes_wrapper .profile_info {
    margin-bottom: 0 !important;
  }

  .main_title h3:before {
    width: 25px;
    height: 25px;
    top: 5px;
  }
  .main_title p.start:before {
    width: 25px;
    height: 25px;
    top: 5px;
  }

  .title_how_it_work {
    margin-bottom: 0 !important;
  }

  .wpb_single_image.vc_align_left {
    margin-bottom: 5px !important;
  }

  .main_title h3 {
    padding-left: 14px;
  }

  .site-footer .widget-area .widget-column.f_guaranteed {
    width: 100%;
  }

  .site-footer .widget-area .widget-column.f_logo {
    width: 100%;
    margin-bottom: 0;
    text-align: center;
  }

  .page_faq .left-cate-faq {
    width: 100%;
  }

  .img_dot:before {
    height: 122px;
    right: -14px;
  }

  .offer_zone:before {
    height: 100px;
  }

  .comparison .text-year {
    font-size: 12px;
  }

  .comparison .price-buy {
    padding: 4px 10px;
    font-size: 12px;
  }

  .comparison .price-info {
    padding: 5px 5px 5px 5px;
    text-align: center;
  }

  .gray-plan {
    flex-direction: column;
  }

  .gray-plan .col-sm-4:nth-child(1),
  .gray-plan .col-sm-4 {
    flex-basis: 100%;
  }

  .gray-plan .col-sm-4 {
    padding: 10px 10px !important;
  }

  .gray-plan .col-sm-4 span {
    text-align: left;
    display: inline-block;
    width: 100%;
  }

  .gray-plan .col-sm-4 span:before {
    top: 1px;
  }

  body .flex-direction-nav a:before {
    font-size: 20px;
  }

  .wpb_gallery .wpb_flexslider .flex-direction-nav a {
    font-size: 0;
  }

  .contact_form .filed_half .field {
    width: 100%;
  }

  .contact_form .filed_half .email.field {
    margin-top: 10px;
  }

  .contact_form input {
    height: 40px;
  }

  .contact_form .message.field {
    margin-top: 10px;
  }

  .breadcrumbs > .main_container {
    flex-direction: column;
  }

  .breadcrumbs .left-breadcrumbs {
    flex-basis: 100%;
    width: 100%;
    text-align: center;
    margin-bottom: 6px;
  }

  .breadcrumbs .right-breadcrumbs {
    flex-basis: 100%;
    width: 100%;
    text-align: center;
  }

  .breadcrumbs {
    height: 10px;
    padding: 20px 0;
  }

  .main_offer_block ul {
    justify-content: space-between;
  }

  .main_offer_block ul li {
    margin: 0 auto !important;
    margin-bottom: 10px !important;
    flex-basis: 0;
  }
  evdpl-featured_tool-accordion-content .col-sm-12 {
    padding-right: 15px;
  }
  .main_title h3:before {
    width: 60px;
    height: 60px;
    top: 5px;
  }
  .main_title p.start:before {
    width: 60px;
    height: 60px;
    top: 5px;
  }

  .post-1305,
  .post-1411,
  .post-17459,
  .post-1413,
  .post-1415,
  .post-1940,
  .subscription  {
    margin-top: 50px;
  }

  #searchform {
    .custom-form:before {
          right: 147px;
          top: 88px;
          height: 20px;
    }
  }

  .comment-form-author,
  .comment-form-cookies-consent {
    width: 100%;
    float: unset;
    padding-right: 0;
  }
  .comment-form-email,
  .form-submit {
    float: unset;
    width: 100%;
  }
}

@media screen and (max-width: 375px) {
  #searchform {
    .custom-form:before {
      right: 137px;
      top: 88px;
      height: 20px;
    }
  }
}

@media screen and (min-width: 768px) {
.top-menu {
  display: flex;
  align-items: center;
}

  .top-menu {
    .main-navigation {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }

  /*SUB MENU*/
  .site-header .main-navigation .menu .sub-menu > li a {
    font-size: 14px;
    color: #333945;
    transition: ease-in-out all 0.3s !important;
    width: 100%;
    box-sizing: border-box;
    padding: 12px 3px;
  }

  .site-header .main-navigation .menu .sub-menu > li a:before {
    content: "";
    background-color: #05643c;
    height: 2px;
    width: 0px;
    left: 0;
    top: 50%;
    position: absolute;
    transition: ease-in-out all 0.3s;
  }

  .site-header .main-navigation .menu .sub-menu > li a:hover:before {
    width: 16px;
  }

  .site-header .main-navigation .menu .sub-menu > li a:hover {
    padding-left: 25px;
    color: #04633b !important;
  }

  .main-navigation li li:hover, .main-navigation li li.focus {
    background: transparent;
  }

  .main-navigation ul ul.sub-menu {
    padding: 10px 20px;
    width: 250px;
  }
  .main_title h3:before {
    width: 80px;
    height: 80px;
    left: 7px;
    top: -13px;
  }

  .main_title p.start:before {
    width: 80px;
    height: 80px;
    left: 7px;
    top: -13px;
  }
}
@media screen and (min-width: 1024px) {
  .main_title h3:before {
    width: 120px;
    height: 120px;
    left: 0;
    top: -37px;
  }
  .main_title p.start:before {
    width: 120px;
    height: 120px;
    left: 0;
    top: -37px;
  }
}

@media screen and (max-width: 420px) {
  .footer-mailchimp .mailing-section-block {
    padding: 0;
  }

  .footer-mailchimp .mainchimp-form .wpcf7-form-control {
    font-size: 14px;
  }

}

@media screen and (min-width: 0 \0 ) {
  .order_boxes > .wpb_column > .vc_column-inner > .wpb_wrapper {
    height: 250px
  }

  .profile_boxes_wrapper .vc_column-inner > .wpb_wrapper {
    height: 250px
  }

  .how_it_work .vc_col-sm-3 > .vc_column-inner > .wpb_wrapper {
    height: 220px;
  }
}

@media (min-width: 1000px) {
  .footer-mailchimp .mailing-section-block {
    padding: 4rem 6rem;
  }
}

@media (min-width: 1300px) {
  .footer-mailchimp .mailing-section-block {
    padding: 8rem 6rem;
  }
}

@media (min-width: 1800px) {
  .footer-mailchimp .mailing-section-block {
    padding: 15rem 6rem;
  }
}

@media (min-width: 2500px) {
  .footer-mailchimp .mailing-section-block {
    padding: 25rem 6rem;
  }
}

@media only screen and (max-width: 992px) and (min-width: 768px)  {
  .left-cate-faq .evdpl-faq-term-title.evdpl-faq-term-family-tree-building {
    line-height: 50px;
  }
}