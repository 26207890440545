.main-navigation li {
  border-bottom: none;
}

.site-header {
  button {
    &.menu-toggle {
      &:hover {
        background: none;
      }
    }
  }

  .main-navigation {
    .custom-logo-link {
      padding: 0;
    }

    .custom-logo a {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

@media screen and (max-width: 1400px) {
  .site-header .main-navigation a {
    font-size: 14px;
  }

  .site-header .main-navigation li {
    padding-top: 15px;
  }

  .site-header .main-navigation .menu .sub-menu > li {
    padding: 0;
  }

  .site-header .main-navigation .menu > li {
    padding-left: 2px;
    padding-right: 2px;
  }
}

@media screen and (max-width: 1199px) {
  .site-footer .widget_nav_menu a {
    font-size: 13px;
  }

  .site-footer .widget_nav_menu li {
    margin-bottom: 10px;
  }

  .site-footer .widget_nav_menu a:hover {
    padding-left: 20px;
  }

  .site-header .main-navigation .menu > li a {
    //font-size: 18px;
    padding-right: 3px;
  }

  .navigation-top .custom-logo-link {
    /*width: 160px;*/
    //width: 133px;
  }

  .site-header .main-navigation li {
    padding-left: 10px;
    padding-right: 10px;
  }

  .site-header .main-navigation .menu .sub-menu > li {
    padding: 0;
  }

  .site-header .main-navigation .menu > li {
    padding-left: 1px;
    padding-right: 1px;
  }

  .site-header .main-navigation .menu > li a {
    padding-left: 3px;
    padding-right: 3px;
  }
}

@media screen and (max-width: 992px) {
  .navigation-top .main-navigation {
    /*width: calc(100% - 160px);*/
    //width: calc(100% - 135px);
    width: 100%;
  }
}

@media screen and (max-width: 768px) and (max-width: 1000px) {
  .site-header .main-navigation .menu > li a {
    font-size: 14px;
  }
}

@media screen and (max-width: 767px) {

  .dropdown-toggle .icon.icon-angle-down {
    top: -0.3em;
  }

  #menu-item-1127 {
    display: none;
  }

  .site-header {
    .main-navigation {
      .menu > li a {
        font-size: 18px;
      }

      li:last-child a:after {
        left: 33px;
      }

      ul ul.sub-menu a {
        font-size: 1.4rem;
      }
    }
  }

  body .site-header .main-navigation .menu .sub-menu .current-menu-item a {
    color: #04633b !important;
  }

  .site-header {
    .main-navigation {
      .menu {
        > li.menu-item-has-children {
          padding-bottom: 20px;
          color: #3B8E3F;

          a {
            display: inline-block;
            vertical-align: middle;
            padding-bottom: 15px;

            &:hover {
              color: #333333;
            }
          }

          .dropdown-toggle {
            position: relative;
            display: inline-block;
            vertical-align: middle;
            padding: 0 0 0 15px;
            //top: 5px;
            right: 10px;
            outline: none;

            &.toggled-on {
              color: #3B8E3F;
            }
          }
        }
      }

      li {
        padding-top: 0;
        padding-bottom: 20px;
        height: auto;

        &.current-menu-item a:before {
          margin-right: 0;
          margin-left: auto;
          visibility: hidden;
        }
        &:last-child a:after {
          left: 36px;
        }
      }
    }
  }

  .navigation-top .main-navigation {
    width: 100vw;
    float: right;
    display: inline-block;
    clear: none;
    text-align: right;
    position: absolute;
    top: 0;
    right: 0;
  }

  /*main menu*/
  .site-header .main-navigation .menu > li {
    height: auto;
    padding: 0;
    display: inline-block;
    width: 100%;
    padding-bottom: 20px;
  }

  /*Sub menu*/
  .main-navigation ul ul.sub-menu {
    position: relative;
    z-index: 1;
    padding: 0;
    width: 100%;
    right: 0;
    float: right;
    text-align: left;

    a {
      padding-right: 26px !important;
    }
  }

  .site-header .main-navigation .menu .sub-menu > li:last-child {
    border-bottom: 0;
  }

  .mobile-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: absolute;
    min-height: 66px;
    z-index: 1;
    //border: 1px solid;
    right: 0;
    width: 65vw;
    align-items: center;
  }

  .register-in {
    .register-button {
      font-family: "Open Sans", sans-serif;
      background-color: #43a047;
      color: #ffffff;
      border-radius: 3px;
      line-height: 19.07px;
      padding: 10px 14px;
      font-size: 1.4rem;
    }
  }

  .navigation-top .main-navigation {
    position: fixed;
    z-index: 2;
  }

  .custom-logo {
    position: relative;
    z-index: 3;

    a {
      width: 120px;
      display: block;
      height: auto;
      padding: 0;
      margin-left: 20px;
      margin-top: 10px;
    }
  }

  .menu-toggle {
    position: absolute;
    z-index: 2;
    color: #43A047;
    font-size: 20px;
    line-height: 1;
    padding-left: 7px;
    padding-top: 19px;

    .icon {
      top: -1px;
    }

    &.toggle-in-nav {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 1;
      top: 0;
    }
  }

  .js .main-navigation > div > ul {
    display: block;
  }

  .mobile-block-menu {
    width: 100%;
    display: block;
    //background-color:rgba(0, 0, 0, 0.35);
    transition: 0.6s;
    transform: translateX(-100%);
    position: fixed;
    top: 0;
    z-index: 10;
    height: 100vh;
  }

  .main-navigation {
    .menu-main-menu-container {
      width: 70%;
      margin-top: 0;
      position: relative;
      z-index: 2;
    }

    > div {
      > ul {
        background: #e4e4e4;
        padding: 0;
        padding-bottom: 0;
      }
    }

    ul ul.sub-menu .current-menu-item a {
      color: #04633b;
    }

    ul.menu {
      text-align: left;
      display: block;
    }

    .menu {
      display: block;
      min-height: 100vh;
      padding-top: 100px;
      padding-left: 25px;
    }

    &.toggled-on {
      .mobile-block-menu {
        display: block;
        transition: 0.5s;
        transform: translateX(0%);
        position: fixed;
        left: 0;
        z-index: 1;

        .toggle-in-nav {
          position: absolute;
          width: 100%;
          height: 100%;
          z-index: 1;
          top: 0;
          //background-color:rgba(0, 0, 0, 0.35);
          //background-color:rgba(0, 0, 0, 0.35);
          animation: fadeIn ease 0.5s;
          -webkit-animation: fadeIn ease 0.5s;
          -moz-animation: fadeIn ease 0.5s;
          -o-animation: fadeIn ease 0.5s;
          -ms-animation: fadeIn ease 0.5s;
          //opacity: 0.35;
          //background:#333;

          //@keyframes fadeIn {
          //  0% {opacity:0;}
          //  50% {opacity:0.35;}
          //}
          //
          //@-moz-keyframes fadeIn {
          //  0% {opacity:0;}
          //  50% {opacity:0.35;}
          //}
          //
          //@-webkit-keyframes fadeIn {
          //  0% {opacity:0;}
          //  50% {opacity:0.35;}
          //}
          //
          //@-o-keyframes fadeIn {
          //  0% {opacity:0;}
          //  50% {opacity:0.35;}
          //}
          //
          //@-ms-keyframes fadeIn {
          //  0% {opacity:0;}
          //  50% {opacity:0.35;}
          //}
        }

        .menu {
          display: block;
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .mobile-header {
    display: none;
  }

  .mobile-block-menu {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .site-header .navigation-top {
    padding-left: 15px;
  }

  .navigation-top .wrap {
    padding-right: 15px !important;
  }

  .navigation-top .main-navigation {
    width: 100%;
  }
}

img {
  pointer-events: none;
  cursor: default;
}