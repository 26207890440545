$color_text: #333333 !global;
$font-family: 'Open Sans' !global;
.category {
  .site-content-contain {
    .site-content {
      padding-top: 0;
    }
  }
}

.page-template-page-blog, .category {
  .breadcrumbs {
    .left-breadcrumbs h1 {
      font-family: "Open Sans";
      font-weight: 700;
      color: $color_text;
      font-size: 24px;
    }
  }

  .blog-container {
    .blog-post-title {
      color: #000000;

      &:hover {
        color: #000000;
      }

      &.first-block-title h2 {
        margin-top: 0;
      }
    }
  }

  .first-block-image {
    max-height: 300px;
    overflow: hidden;
  }
}
#searchsubmit {
  display: none;
}

.blog-grid {
  display: flex;
  flex-wrap: wrap;

  .blog-grid-in {
    display: flex;
    align-items: stretch;
    flex-direction: column;
    margin-bottom: 28px;
  }
}

.page-template-page-blog {
  .list-taxonomy {
    &.blog-taxonomy {
      ul {
        li {
          &:first-child {
            border: solid 0 #43A047;
            border-bottom-width: 2px;
          }
        }
      }
    }
  }
}

.list-taxonomy {
  margin-bottom: 30px;

  &.blog-taxonomy {
    ul {
      li {
        border: 0 solid #E0E0E0;
        border-bottom-width: 1px;

        &:hover {
          //background: #015E35;
          font-weight: bold;
          border: 0 solid #43A047;
          border-bottom-width: 1px;
        }

        a {
          text-decoration: none;
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
          align-items: center;
          text-align: center;
          color: $color_text;

          &:hover {
            color: $color_text;
            text-decoration: none;
          }
        }
      }
    }
  }

  a {
    font-family: 'Open Sans', sans-serif;
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 24px;
    text-decoration: none;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    padding: 30px 10px 0 0;
    list-style-type: none;
    //border-bottom: 2px solid #cacaca;

    li {
      padding: 10px;
      //background: #dcdcdc;
      margin-right: 20px;
      //border-top-left-radius: 15px;
      //border-top-right-radius: 15px;
      //border-bottom-left-radius: 15px;
      //border-bottom-right-radius: 15px;
      font-weight: bold;
      margin-bottom: 10px;

      a {
        color: #000;

        &:hover {
          text-decoration: none;
        }
      }

      &.current-cat {
        //background: #015E35;
        border: solid 0 #43A047;
        border-bottom-width: 2px;

        a {
          font-family: $font-family;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
          align-items: center;
          text-align: center;
          color: $color_text;
        }

        &:hover {
          font-weight: bold;
        }
      }

      &:hover {
        //background: #dcdcdc;
      }
    }
  }
}

@media (max-width: 468px) {
  .list-taxonomy {
    ul {
      padding: 0;
    }
  }
}

.pagination-blog {
  a, span {
    font-size: 1.5rem;
  }

}

.author-bio {
  padding: 20px 0;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.author-avatar {
  float: left;
  margin-right: 20px;

  img {
    width: 80px;
    min-width: 80px;
    border: 1px solid #D6D6D6;
    border-radius: 50px;
  }
}

.author-details {
  overflow: hidden;
}

#sidebar-blog {
  .widget {
    padding-bottom: 0;
  }

  .sidebar-search-form {
    padding-top: 7px;

    label {
      display: none;
    }
  }

  .search-form .search-submit .icon {
    height: 22px;
    top: 0;
    width: 24px;
  }

  .latest-posts {
    a {
      color: #337ab7;
      font-size: 1.2em;
      font-weight: bold;
    }

    .excerpt {
      font-size: 0.8em;
      font-weight: normal;
    }

    .current-post {
      display: flex;
      position: relative;
      margin-top: 2rem;
      padding-bottom: 2rem;
      border-bottom: #ddd 1px solid;

      &:last-child {
        border-bottom: none;
      }
    }

    .block-img {
      width: 115px;
      height: 75px;
      min-width: 115px;
      margin-right: 1rem;
      background-size: cover !important;
      background-position: top center !important;
    }

    .current-post-title {
      align-items: flex-start;

      a {
        color: #666666;
        font-style: normal;
        font-size: 1.2em;
        line-height: 1.2;
        margin-top: -3px;
        display: block;
      }
      p {
        img {
          vertical-align: text-top;
        }
      }
    }
  }
}

.widget {
  &.widget_categories {
    ul.category-list {
      list-style-type: none;

      li {
        a {
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 19px;
          text-transform: capitalize;
          color: #333333;
        }
      }
    }
  }
}

.post-container {
  .main-post-block {
    ul, ol {
      padding-left: 20px;
    }
  }

  .vc_btn3-container.vc_btn3-inline {
    display: flex;
    justify-content: center;
    padding-top: 30px;
    margin-top: -20px;
  }

  h2, h3 {
    padding-top: 0.1em;
  }

  h2 {
    color: #000;
  }

  h3 {
    color: #333;
  }
  #reply-title,
  #commentform,
  .comment-form-comment label{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    color: #333333;
  }
  #commentform,
  .comment-form-comment label {
    font-size: 12px;
  }

  #commentform {
    textarea {
      border: 1px solid #E0E0E0;
      border-radius: 3px;
    }
  }
}

@media (max-width: 768px) {
  .page-template-page-blog, .category {
    .breadcrumbs {
      height: auto;
      padding: 20px 0;

      .container {
        justify-content: center;
        flex-direction: column;
      }

      //.left-breadcrumbs {
      //  padding-bottom: 20px;
      //}
    }
  }

  .list-taxonomy {
    ul {
      //flex-direction: column;
      align-items: center;

      li {
        min-width: 199px;
        text-align: center;
      }
    }
  }
}

@media (min-width: 768px) {
  .author-bio {
    flex-direction: row;
  }

  .list-taxonomy {
    a {
      font-size: 1.8rem;
    }

    ul {
      li {
        padding: 15px 25px;
        margin-bottom: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }

  .post-container {
    .thumbnail-block {
      max-height: 300px;
      overflow: hidden;
    }
  }
}

#searchform {
  .custom-form {
    position: relative;

    #s {
      display: inline;
      padding: 0.7em;
      width: 84%;
      border: 1px solid #E0E0E0;
      box-sizing: border-box;
      border-radius: 3px;
      height: 58px;
      line-height: 58px;
      padding-left: 40px;
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      align-items: center;

      &::placeholder {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        align-items: center;
        color: #AAAAAA;
      }
    }
    #searchsubmit {
      width: 172px;
      height: 60px;

      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 14px;
      //align-items: center;
      text-align: left;
      color: #FFFFFF;
      background: #43A047;
      border-radius: 3px;
      margin-left: -6px;
      padding-left: 50px;
    }
    &:before {
      content: '';
      background: url(assets/images/new-icons/arrow.png) no-repeat;
      position: absolute;
      right: 50px;
      top: 21px;
      width: 20px;
      height: 100%;
      background-position: top;
      z-index: 1;
    }
    &:after {
      content: '';
      background: url(assets/images/new-icons/ellipse.png) no-repeat;
      position: absolute;
      left: 10px;
      top: 14px;
      width: 27px;
      height: 27px;
      background-position: top;
      z-index: 1;
    }
  }
}

.main-post-blog-page {
  position: relative;
  margin-bottom: 70px;
  margin-right: 0 !important;
  margin-left: 0 !important;
  background: #F8F7F7;
  border-radius: 10px;
  overflow: hidden;

  .col-main-image {
    position: relative;
    float: left;
    width: 33%;
    a {
      display: block;
      overflow: hidden;
      img {
        max-width: 96%;
        height: 240px;
      }
    }
  }

  .col-main-text {
    float: left;
    position: relative;
    width: 66%;
    padding: 35px;

    h2 {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 33px;
      color: $color_text;
      margin-bottom: 17px;
      margin-top: 0 !important;
    }
    .excerpt {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 25px;
      color: #515151;
    }
    .date-publick {
      margin-top: 20px;
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: $color_text;
      img {
        vertical-align: text-top;
      }
    }
  }
}

.page-template-page-blog-php,
.category {
  .date-publick {
    margin-top: 17px;
  }
  #searchsubmit {
    display: inline-block;
  }
  .attachment-blog-images {
    border-radius: 8px;
    width: 360px;
    height: 240px;
  }
  .alm-listing {
    overflow: hidden;
    margin-bottom: 28px;
  }
  .alm-btn-wrap {
    text-align: center;
  }
  .alm-load-more-btn {
    width: 180px;
    height: 50px;
    border: 1px solid #43A047;
    border-radius: 3px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    color: #fff;
    background-color: #43A047;
    &:hover {
      border: 1px solid #43A047;
      border-radius: 3px;
      background-color: #3B8E3F;
    }
  }
}

.single-post {
  h1 {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 65px;
    color: $color_text;
  }
  h2 {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    color: #181D1F;
  }
  .date-post-when {
    margin-bottom: 17px;
    img {
      vertical-align: text-top !important;
    }
  }
  .thumbnail-block {
    margin-bottom: 31px;
  }
  .vc_btn3-color-green {
    width: 280px;
    height: 45px;
    background-color: #43A047 !important;
    border-radius: 3px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px !important;
    //align-items: center;
    text-align: left !important;
    text-transform: capitalize;
    color: #FFFFFF;
    padding-left: 25px !important;

    &:before {
        content: '';
        background: url(assets/images/new-icons/arrow.png) no-repeat;
        position: absolute;
        right: 25px;
        top: 11px;
        width: 20px;
        height: 21px;
        background-position: top;
        z-index: 100;
    }
  }
}

.search, .archive {
  #s {
    width: 84% !important;
  }
  h1 {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 65px;
    color: $color_text;
    span {
      font-size: 48px;
      line-height: 65px;
    }
  }

  .site-main {
    .entry-header {
      padding: 0;
    }
    article {
      h2 {
        margin-top: 0;
        margin-bottom: 6px;
      }
    }
  }
  .popular-posts {
    h2 {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
      color: $color_text;
    }
    .wpp-list {
      li {
        margin-bottom: 20px;
        border: unset;
        a.wpp-post-title {
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
          color: $color_text;
          text-decoration: none;
        }
        .wpp-thumbnail {
          border-radius: 8px;
        }
      }

      .post-stats {
        .wpp-views {
          //display: none;
        }
      }
    }
    border: solid 0 #E0E0E0;
    border-bottom-width: 1px;
  }
  .widget-title {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: $color_text;
  }
  .widget_categories {
    ul {
      li {
        border: unset;
        a {
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 19px;
          text-transform: capitalize;
          color: $color_text;
          text-decoration: unset;
        }
      }
    }
    border: solid 0 #E0E0E0;
    border-bottom-width: 1px;
  }
  .widget_tag_cloud {
    p {
      a {
        font-family: $font-family;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 24px;
        //display: flex;
        align-items: center;
        text-align: center;
        text-transform: capitalize;
        color: $color_text;
      }
    }
    //border: solid 0 #E0E0E0;
    //border-bottom-width: 1px;
  }
  .entry-title {
    a {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 33px;
      color: $color_text;
    }
  }
  .entry-summary {
    //p {
      font-family: $font-family;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 25px;
      color: #515151;
    //}
  }
  .search-excerpt {
    background: #A5D6A7;
  }
  .post-thumbnail {
    display: inline-block;
    float: left;
    padding-right: 24px;
    //max-width: 25%;
    width: 25%;
    img {
      border-radius: 8px;
    }
  }
  .article-block-search {
    max-width: 75%;
    display: inline-block;
    float: left;
  }
  @media screen and (min-width: 48em) {
      .site-main {
        article {
          padding-bottom: 29px;
          overflow: hidden;
        }
      }
  }

  @media screen and (min-width: 48em) {
    .site-content {
      padding: 1.5em 0 0;
    }
  }
}

.search-results,
.search-no-results {
  #s {
    width: 99% !important;
  }
}

.single-post,
.search-no-results,
.search-results {
  #searchform .custom-form:before {
    content: unset;
  }
}
//.archive {
//  #s {
//    width: 84% !important;
//  }
//}
@media  (max-width: 1024px) {
  #searchform {
    .custom-form {
      #s {
        width: 81% !important;
      }
    }
  }
}

@media  (max-width: 992px) {
  #searchform {
    .custom-form {
      #s {
        width: 76% !important;
      }
    }
  }
}
@media  (max-width: 820px) {
  #searchform {
    .custom-form {
      #s {
        width: 75% !important;
      }
    }
  }
}
@media  (max-width: 468px) {
  #searchform {
    .custom-form {
      text-align: center;
      #s {
        width: 100% !important;
      }
      #searchsubmit {
        margin-top: 10px;
      }
    }
  }
}

@media (max-width: 468px) {
  .main-post-blog-page {

    .col-main-image {
      //padding-left: 15px;
      //padding-right: 15px;
      float: unset;
      width: 100%;
      a {
        img {
          width: 100%;
          max-width: 100%;
        }
      }
    }
    .col-main-text {
      position: relative;
      //width: 93%;
      //height: auto;
      padding: 15px;
      //background: #F7F9D2;
      border-radius: 10px;
      margin: 0 auto;
      margin-top: 10px;
      bottom: unset;
      right: unset;
      float: unset;
      width: 100%;
    }
    margin-bottom: 20px;
  }
  .ajax-load-more-wrap {
    margin-left: -15px;
    margin-right: -15px;
  }
  .list-taxonomy {
    ul {
      flex-direction: column;
    }
  }
  .archive {
    #s {
      width: 100% !important;
    }
  }
}
img,
.block-img {
  border-radius: 8px;
}

body {
  .wpp-cards {
    li {
      .wpp-thumbnail {
        border-radius: 8px !important;
      }
    }
  }
}

.wp-pagenavi {
  margin-top: 60px;
  span, a {
    text-decoration: none !important;
    border: none !important;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #515151;
  }
  .current {
    display: none !important;
  }
}

.wpp-item-data {
  img {
    vertical-align: text-top;
  }
}

@media (min-width: 768px) and (max-width: 1180px) {
  .main-post-blog-page {
    .col-main-image {
      width: 50%;

      a {
        img {
          width: 100%;
          max-width: 100%;
        }
      }
    }

    .col-main-text {
      width: 50%;

      .excerpt {
        display: none;
      }
    }
  }
}