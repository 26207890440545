.base-container {
  flex-direction: column;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  padding-bottom: 0;
}

.btn-redesign-primary {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  background: #43a047;
  border-radius: 3px;
  transition: .5s ease;
  width: 100% !important;
  padding: 12px;
  outline: none;
  text-decoration: none !important;
  border: none;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  align-items: center;
  text-align: center;
  text-transform: capitalize;
  color: #FFFFFF !important;
}

.btn-redesign-secondary {
  font-family: Open Sans, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #333;
  border: 1px solid #43a047;
  box-sizing: border-box;
  border-radius: 3px;
  width: 100%;
  display: block;
  padding: 13px;
  transition: .5s ease;
  text-align: center;
  outline: none;
  background: none;
  text-decoration: none !important;
  //&:after {
  //  content: '';
  //  background: url(assets/images/new-icons/group_295.png) no-repeat;
  //  position: absolute;
  //  left: 20px;
  //  top: 12px;
  //  width: 27px;
  //  height: 27px;
  //  background-position: top;
  //  z-index: 100;
  //}
}

.btn-redesign-secondary:not(.not-btn):focus, .btn-redesign-secondary:not(.not-btn):hover {
  text-decoration: none;
  color: inherit;
  border: 1px solid #3b8e3f;
}

.subscription {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-top: 0px;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto
}

.subscription__body {
  margin-top: 60px;
  padding: 0 48px
}

@media (max-width: 1366px) {
  .subscription__body {
    margin-top: 16px
  }
}

@media (max-width: 960px) {
  .subscription__body {
    padding: 0 30px
  }
}

@media (max-width: 656px) {
  .subscription__body {
    padding: 0 20px
  }
}

.subscription__title {
  margin-bottom: 20px;
  text-transform: capitalize;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 65px;
  text-align: center;
  color: #333333;
}

@media (max-width: 1366px) {
  .subscription__title {
    margin-bottom: 12px;
    font-size: 24px
  }
}

.subscription__wrapper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: center;
  align-items: center;
  max-width: 1206px;
  width: 100%;
  margin: 0 auto
}

.subscription__plan {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0 30px;
  width: 100%;
  color: #333
}

@media (max-width: 960px) {
  .subscription__plan {
    grid-gap: 0 16px
  }
}

@media (max-width: 500px) {
  .subscription__plan {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    grid-gap: 20px 0
  }
}

.subscription__row {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 27px 1fr;
  grid-template-columns: 27px 1fr;
  grid-gap: 0 11px
}

@media (max-width: 1366px) {
  .subscription__row {
    -ms-grid-columns: 17px 1fr;
    grid-template-columns: 17px 1fr
  }
}

.subscription__row-title {
  margin: 10px 0 0;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  color: #333333;
}

.subscription__col {
  display: -ms-flexbox;
  display: flex
}

.subscription__link-icon {
  display: none
}

.subscription__link {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  max-width: 416px;
  width: 100%;
  min-height: 50px;
  max-height: 50px;
  margin: 0 auto;
  padding: 0 10px;
  border: thin solid #a5d6a7;
  border-radius: 3px;
  //color: #333;
  //font-size: 24px;
  transition: .4s ease;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  align-items: center;
  text-align: center;
  text-transform: capitalize;
  color: #FFFFFF;
}

@media (max-width: 1366px) {
  .subscription__link {
    min-height: 50px;
    max-height: 50px;
    font-size: 18px
  }
}

.subscription__link_current {
  position: relative;
  color: #333 !important
}

.subscription__link_current:hover {
  background-color: transparent !important;
  color: #333 !important
}

.subscription__link_current .subscription__link-icon {
  position: absolute;
  left: 6px;
  top: 50%;
  width: 25px;
  height: 25px;
  display: block;
  transform: translateY(-50%)
}

.subscription__link:hover {
  text-decoration: none;
  color: #43a047
}

.subscription__link_free {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-transform: uppercase
}

.subscription__link_empty {
  opacity: 0
}

.subscription__link + .subscription__link {
  margin-top: 15px
}

@media (max-width: 1366px) {
  .subscription__link + .subscription__link {
    margin-top: 10px
  }
}

.subscription__link-price {
  margin-bottom: 2px;
  font-size: 16px
}

@media (max-width: 1366px) {
  .subscription__link-price {
    font-size: 18px
  }
}

.subscription__link-period {
  margin-bottom: 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
}

@media (max-width: 1366px) {
  .subscription__link-period {
    font-size: 12px
  }
}

.subscription__col-title {
  margin-bottom: 10px
}

@media (max-width: 656px) {
  .subscription__col-title {
    font-size: 20px
  }
}

.subscription__plan-info {
  display: -ms-grid;
  display: grid;
  grid-gap: 15px 0
}

@media (max-width: 1366px) {
  .subscription__plan-info {
    grid-gap: 9px 0
  }
}

@media (max-width: 768px) {
  .subscription__plan-info {
    grid-gap: 6px 0
  }
}

.subscription__col-name {
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #333333;
}


@media (max-width: 768px) {
  .subscription__col-name {
    font-size: 12px
  }
}

.subscription__col-name:first-letter {
  text-transform: uppercase
}

.subscription__col-name_indent {
  margin-top: 2px
}

.subscription__col-message {
  font-size: 14px;
  line-height: 1.6;
  color: #43a047
}

@media (max-width: 656px) {
  .subscription__col-message {
    font-size: 12px
  }
}

.subscription .modal {
  display: block
}

.subscription .modal-body {
  position: static
}

.subscription__plan-card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 4px 80px rgba(0, 0, 0, .07)
}

.subscription__plan-card:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 10px;
  background-color: #a5d6a7;
  border-radius: 10px 10px 0 0
}

.subscription__plan-title {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  color: #333333;
  margin: 0 0 25px
}

@media (max-width: 1366px) {
  .subscription__plan-title {
    font-size: 20px;
    margin-bottom: 15px
  }
}

.subscription__plan-footer-wrapper {
  position: sticky;
  position: -webkit-sticky;
  bottom: 0;
  width: 100%;
  padding-bottom: 30px;
  background-color: #fff;
  box-shadow: inset 0 -70px 105px 0 rgba(0, 0, 0, .04)
}

.subscription__plan-card-content {
  padding: 42px 86px 20px
}

@media (max-width: 1366px) {
  .subscription__plan-card-content {
    padding: 16px 86px 20px
  }
}

@media (max-width: 1024px) {
  .subscription__plan-card-content {
    padding: 16px 56px 10px
  }
}

@media (max-width: 960px) {
  .subscription__plan-card-content {
    padding: 16px 30px 10px
  }
}

@media (max-width: 768px) {
  .subscription__plan-card-content {
    padding: 16px 20px 10px
  }
}

.subscription__plan-footer {
  padding: 16px 86px 20px;
  background-color: #fff;
  border-top: 1px solid #e0e0e0;
  border-radius: 0 0 10px 10px;
  overflow: hidden
}

@media (max-width: 1366px) {
  .subscription__plan-footer {
    padding: 16px 86px 20px
  }
}

@media (max-width: 1024px) {
  .subscription__plan-footer {
    padding: 16px 56px 10px
  }
}

@media (max-width: 960px) {
  .subscription__plan-footer {
    padding: 16px 30px 10px
  }
}

@media (max-width: 768px) {
  .subscription__plan-footer {
    padding: 16px 20px 10px
  }
}

.rtl .subscription__row-pricing .subscription__col:nth-child(2) {
  margin-right: 0;
  margin-left: 18px
}

.rtl .subscription__link_current .subscription__link-icon {
  left: auto;
  right: 6px
}

.rtl .subscription__nav-col:only-child {
  margin-left: 0;
  margin-right: auto
}

.subscription-page .base-container {
  padding-bottom: 0
}