body {
  /*font-family: 'open_sansregular';*/
  font-family: 'Open Sans', sans-serif;
  padding-top: 66px;

  p, span, a {
    font-size: 1.4rem;
  }
}

.nav-links {
  a {
    font-size: 1.2rem;
  }
  span {
    font-size: 1.2rem;
  }
}

.main_title h3 {
  //font-family: 'futura_ltregular';
}

.main_title h2 {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 65px;
  color: #333333;
  //margin-left: -30px;
}

a:focus {
  outline: none;
}

.main_container {
  max-width: 1340px;
  width: 100%;
  margin: 0 auto !important;
}

.entry-content {
  width: 100% !important;
  float: none !important;
  margin: 0 auto;
  text-align: center;
}

.home header.entry-header {
  display: none;
}

.title_h1 {
  h1 {
    font-size: 48px;
    font-weight: 700;
    line-height: 65.37px;
    font-style: normal;
    position: relative;

    @media (min-width: 768px) {
      &:before {
        content: '';
        width: 105px;
        height: 105px;
        background: #F7F9D2;
        position: absolute;
        left: -25px;
        top: -34px;
        border-radius: 50%;
        z-index: -1;
      }
    }
  }
}

h2 {
  &.title_h2 {
    font-size: 48px;
    font-weight: 700;
    line-height: 65.37px;
    font-style: normal;
    position: relative;

    @media (min-width: 768px) {
      &:before {
        content: '';
        width: 105px;
        height: 105px;
        background: #ffffff;
        position: absolute;
        left: -25px;
        top: -34px;
        border-radius: 50%;
        z-index: -1;
      }
    }
  }
}

.main_title h3 {
  letter-spacing: 0.8px;
  margin: 0;
  padding-left: 29px;
  min-height: 40px;
  position: relative;
  display: inline-block;
  margin-left: -30px;
}

.main_title h2 {
  font-size: 34px;
  color: #061d08;
  /*font-family: 'futura_ltbold';*/
  padding: 0;
  margin: 0;
  line-height: normal;
  margin-top: 10px;
}

.treemly_right .main_title {
  text-align: left;
}

.arrow-before {
  a {
    font-family: 'Open Sans', sans-serif;
    text-align: center!important;
    min-width: 180px;
  }
}

.arrow-before .fa-chevron-circle-right {
  &:before {
    content: '';
    background: url(assets/images/new-icons/arrow.svg) no-repeat;
    position: absolute;
    left: -27px;
    top: 1px;
    width: 20px;
    height: 100%;
    background-position: 0;
  }

  @media (max-width: 768px) {
    &:before {
      left: -5px;
      top: -2px;
    }
  }
}

.arrow-before .vc_btn3.vc_btn3-size-md .vc_btn3-icon {
  height: 22px;
}

.main_title h3:before,
.main_title p.start:before {
  content: '';
  width: 119px;
  height: 119px;
  background: #F7F9D2;
  position: absolute;
  left: 0;
  top: -37px;
  border-radius: 50%;
  z-index: 0;
}

.main_title > .wpb_wrapper {
  display: inline-block;
}

.main_title {
  position: relative;
}


.main_title span {
  position: relative;
  margin-top: 11px;
  display: inline-block;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 48px !important;
  line-height: 65px;
  color: #333333;
  margin-bottom: 20px !important;
  padding-left: 20px;
}

.treemly_info ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.treemly_info ul li {
  font-size: 20px;
  color: #061d08;
  /*font-family: 'open_sanssemibold';*/
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  text-align: left;
  position: relative;
  padding-left: 50px;
  margin-bottom: 30px;
}

.treemly_info ul li:before {
  content: '';
  background: url(assets/images/multi-icon.png);
  position: absolute;
  left: 0;
  top: 0;
  width: 30px;
  height: 30px;
  background-position: -4px -5px;
}

.treemly_right {
  padding-left: 90px !important;
}

.treemly_img img {
  overflow: hidden;
}

.treemly_img:before {
  content: '';
  background: url(assets/images/dot_repeater_large.png);
  position: absolute;
  top: -90px;
  width: 350px;
  height: 400px;
  left: -130px;
}

.treemly_img {
  position: relative;
  margin-top: 100px;
}

/*How it works*/

.how_it_work img {
  max-width: 70px;
  width: 100%;
}

.how_it_work .vc_col-sm-3 > .vc_column-inner {
  background: #fff;
  box-shadow: 6.101px 13.703px 24px 0px rgba(0, 0, 0, 0.08);
  text-align: center;
  padding: 20px;
  border-radius: 14px;
  width: 90%;
  margin: 0 auto;
  transition: 0.3s;
}

.how_it_work .vc_col-sm-3 > .vc_column-inner:hover {
  box-shadow: 0px 9px 8px 6px rgba(0, 0, 0, 0.08);
}

.how_it_work .wpb_single_image .vc_figure {
  width: 100%;
  text-align: center;
}

.dot_how_it_works:before {
  content: '';
  background: url(assets/images/how_it_work_dot.png) no-repeat;
  width: 100%;
  height: 400px;
  position: absolute;
  //left: 94%;
  bottom: -120px;
}

.how_it_work .vc_col-sm-3 > .vc_column-inner > .wpb_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 220px;
}

.how_it_work .wpb_content_element {
  margin: 0;
}

.title_how_it_work p {
  font-size: 20px;
  color: #061d08;
  /*font-family: 'open_sanssemibold';*/
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  line-height: 24px;
}

.title_how_it_work {
  margin-top: 30px !important;
}

.how_it_work .main_title {
  margin-bottom: 50px;
}

.how_it_work {
  margin-top: 70px !important;
}

.btn_common a:hover {
  color: #005d34;
  border-color: #005d34;
  background: transparent;
}

.btn_common a {
  background: #005d34;
  color: #fff;
  font-size: 18px;
  /*font-family: 'open_sanssemibold';*/
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  padding: 16px 40px;
  display: inline-block;
  border: 2px solid transparent;
  text-decoration: none;
  box-shadow: none !important;
  transition: 0.3s;
}

.how_it_work .btn_common {
  margin-top: 70px;
}

/*Powerful Genealogy Tools*/

.geneology_tools:before {
  content: '';
  left: 0;
  right: 0;
  background: #f3f3f3;
  display: block !important;
  width: 100%;
  height: 80%;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

.geneology_tools {
  position: relative;
}

.geneology_tools .wpb_single_image {
  margin: 0;
}

.chart_img:before {
  content: '';
  position: absolute;
  right: 100%;
  background: #f3f3f3;
  width: 3160px;
  height: 80%;
  position: absolute;
  transform: translate(0%, -50%);
  top: 50%;
}

.flat_content {
  text-align: left;
  padding-left: 100px;
}

.semi-title {
  position: relative;
}

.semi-title h2 {
  font-size: 30px;
  color: #061d08;
  font-family: 'futura_ltbold';
}

.semi-title h2:before {
  content: '';
  position: absolute;
  left: 0;
  bottom: -8px;
  width: 70px;
  height: 2px;
  background: #061d08;
}

.flat_content {
  padding-left: 80px !important;
  padding-right: 190px !important;
  padding-top: 80px !important;
  padding-bottom: 80px !important;
}

.flat_content p {
  font-size: 18px;
  color: #061d08;
  line-height: 34px;
  margin: 0;
}

.geneology_tools .btn_common {
  margin-bottom: 0;
  margin-top: 50px;
}

/*.semi-title {    margin-bottom: 0;}   */

/*two_column*/

.chart_column .chart_text {
  text-align: left;
}

.chart_column {
  margin-top: 120px !important;
  margin-bottom: 120px !important;
}

.column_info p {
  font-size: 18px;
  color: #061d08;
  line-height: 33px;
}

/*.column_info .semi-title {    margin-bottom: 0;}*/

.column_info .btn_common {
  margin-top: 60px;
}

.img_dot:before {
  content: '';
  background: url(assets/images/dynasty_treemly_dot.png) no-repeat;
  position: absolute;
  bottom: -20px;
  right: -2px;
  width: 310px;
  height: 265px;
}

/*Offer_section*/

.offer_title_main h2 {
  color: #fff;
  font-size: 30px;
  font-family: 'futura_ltbold';
  letter-spacing: 0.5px;
  margin: 0;
}

.offer_title p {
  color: #fff;
  font-size: 20px;
}

.offer_title {
  margin-bottom: 0 !important;
}

.offer_boxes .wpb_single_image {
  margin-bottom: 20px;
}

.offer_boxes .wpb_single_image .vc_single_image-wrapper:before {
  content: '';
  position: absolute;
  right: 0;
  top: -20px;
  width: 65px;
  height: 65px;
  background: #3f9071;
  border-radius: 50%;
  left: -40px;
  margin: 0 auto;
}

.offer_boxes .wpb_single_image .vc_single_image-wrapper {
  position: relative;
}

.offer_boxes .wpb_single_image .vc_single_image-wrapper img {
  position: relative;
}

//.custom_btn_wrapper .vc_general {
//  background: #d6df27 !important;
//  border-color: transparent !important;
//  color: #061d08 !important;
//  font-size: 20px !important;
//  padding: 20px 60px !important;
//  /*font-family: 'open_sanssemibold';*/
//  font-weight: 600;
//  font-family: 'Open Sans', sans-serif;
//  border-width: 2px !important;
//  box-shadow: 0 7px 12px 0 rgba(0, 0, 0, 0.09) !important;
//}

.offer_zone .custom_btn_wrapper {
  margin: 0;
}

.offer_zone {
  padding-top: 50px;
  padding-bottom: 80px;
  margin-bottom: 40px;
  position: relative;
}

.offer_btn {
  position: absolute;
  bottom: -110px;
  right: 0;
  left: 0;
  margin: 0;
}

.offer_boxes {
  padding: 30px 0;
}

.custom_btn_wrapper .vc_general:hover {
  background: #036239 !important;
  color: #fff !important;
  //border-color: #d6df27 !important;
}

.offer_zone:before {
  content: '';
  background: url(assets/images/tools_dt.png) no-repeat;
  position: absolute;
  top: 52%;
  width: 470px;
  height: 400px;
  left: -16px;
  display: inline-block !important;
}

/*Order Prints of Your Treemily*/

.order_boxes .wpb_single_image {
  max-width: 100px;
  margin-bottom: 20px;
}

.order_boxes .order_point_title {
  margin-bottom: 0;
}

.order_point_title p {
  font-size: 20px;
  color: #061d08;
  /*font-family: 'open_sanssemibold';*/
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  line-height: 26px;
}

.order_boxes > .wpb_column > .vc_column-inner > .wpb_wrapper {
  background: #f4f4f4;
  min-height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 15px;
  transition: 0.3s;
}

.order_boxes > .wpb_column > .vc_column-inner > .wpb_wrapper:hover {
  box-shadow: 0px 9px 7px 0px rgba(0, 0, 0, 0.08);
  background: #fff;
}

/*Why We're Different*/

.bussiness_company {
  margin-top: 100px !important;
}

.bussiness_listing {
  margin-top: 30px;
}

.build-family ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.build-family ul li {
  font-size: 14px;
  line-height: 24.51px;
  color: #515151;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  text-align: left;
  position: relative;
  padding-left: 37px;
  margin-bottom: 20px;
}

.build-family ul li:before {
  content: '';
  background: url(assets/images/new-icons/checkmark.svg) no-repeat;
  position: absolute;
  left: 0;
  top: -2px;
  width: 29px;
  height: 29px;
  background-position: 0;
}

.we_re_diffrent {
  max-width: 450px;
}

.we_re_diffrent {
  max-width: 500px;
}

.we_re_diffrent ul {
  margin: 0;
  padding: 0;
  list-style: none;
  padding-left: 40px;
}

.we_re_diffrent ul li {
  text-align: left;
  font-size: 18px;
  padding: 12px 40px;
  border: 1px solid #ebebeb;
  margin-bottom: 22px;
  position: relative;
  padding-left: 65px;
}

.we_re_diffrent ul li:before {
  content: '';
  background: url(assets/images/multi-icon.png) no-repeat;
  position: absolute;
  left: 30px;
  top: 0;
  width: 20px;
  height: 100%;
  background-position: -55px 3px;
}

.we_re_diffrent ul li:hover {
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.08);
  color: #005d34;
  font-weight: 600;
}

.bussiness_img:before {
  content: '';
  background: url(assets/images/why_we_are_diffrent_dot.png) no-repeat;
  position: absolute;
  bottom: -20px;
  left: -62px;
  width: 260px;
  height: 305px;
}

#hs_testimonial_theme_1 .hs_client_message {
  color: #061d08;
  font-size: 18px !important;
}

.home_testimonials .hs_client_name {
  color: #b5b5b5;
  font-size: 18px;
  text-transform: capitalize;
  font-family: 'futura_ltregular';
}

.home_testimonials .hs_testimonial_wrapper {
  max-width: 90%;
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.home_testimonials div#hs_testimonial_theme_1 {
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  padding: 40px;
}

.home_testimonials .owl-theme .owl-nav {
  margin-top: 10px;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 40%;
  width: 100%;
  left: 50%;
  margin: 0;
}

.home_testimonials .owl-theme .owl-nav .owl-prev {
  left: -30px;
  position: absolute;
}

.home_testimonials .owl-theme .owl-nav .owl-next {
  position: absolute;
  right: -30px;
}

.home_testimonials .owl-theme .owl-nav [class*=owl-] {
  background-image: -moz-linear-gradient(0deg, rgb(0, 93, 52) 0%, rgb(30, 140, 103) 100%);
  background-image: -webkit-linear-gradient(0deg, rgb(0, 93, 52) 0%, rgb(30, 140, 103) 100%);
  background-image: -ms-linear-gradient(0deg, rgb(0, 93, 52) 0%, rgb(30, 140, 103) 100%);
  width: 50px;
  height: 50px;
  line-height: 42px;
  font-size: 26px;
  border-radius: 50%;
}

.home_testimonials {
  margin-top: 100px;
}

.profile_boxes_wrapper .vc_column-inner > .wpb_wrapper {
  box-shadow: 6.101px 13.703px 24px 0px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  padding: 20px;
  min-height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: 0.3s;
}

.profile_boxes_wrapper .vc_column-inner > .wpb_wrapper:hover {
  box-shadow: 0px 9px 8px 6px rgba(0, 0, 0, 0.08);
}

.profile_boxes_wrapper .profile_info h4 {
  margin: 0;
  padding: 0;
  font-size: 20px;
  color: #061d08;
  margin-top: 6px;
}

.profile_boxes_wrapper .profile_info h3 {
  margin: 0;
  padding: 0;
  font-size: 30px;
  color: #061d08;
  /*font-family: 'open_sansbold'*/
  font-family: 'open_sansbold';
  font-weight: 900;
  margin-top: 8px;
}

.profile_boxes_wrapper .profile_info {
  margin-bottom: 0 !important;
}

.profile_boxes_wrapper .wpb_single_image {
  margin: 0;
}

.section_profile {
  margin-top: 100px !important;
}

.section_order_printing {
  margin-top: 100px !important;
}

.section_order_printing .main_title {
  margin-bottom: 45px;
}

/*Footer section*/

.site-footer .widget-area .widget-column {
  width: 25%;
}

footer#colophon {
  background: #f8f8f8;
  overflow: hidden;
  position: relative;
}

.site-footer .widget-area .widget-column {
  list-style: none;
}

.site-footer .widget-area .widget-column h2.widget-title {
  color: #333945;
  font-size: 20px;
  font-family: 'futura_ltbold';
  text-transform: capitalize;
  margin: 0;
  letter-spacing: 0;
}

.site-footer .widget_nav_menu {
  padding: 0;
  line-height: normal;
  border: 0;
}

.site-footer .widget_nav_menu a:hover,
.site-footer .widget-area a:hover {
  box-shadow: none !important;
}

.site-footer .widget_nav_menu li {
  padding: 0;
  border: 0;
  margin-bottom: 22px;
}

.site-footer .widget_nav_menu a {
  border: 0;
  box-shadow: none !important;
  text-decoration: none;
  font-size: 16px;
  color: #333945 !important;
  position: relative;
  transition: ease-in-out all 0.3s !important;
}

.site-footer .widget ul {
  margin-top: 30px;
}

.site-footer .widget_nav_menu a:before {
  content: "";
  background-color: #05643c;
  height: 2px;
  width: 0px;
  left: 0;
  top: 50%;
  position: absolute;
  transition: ease-in-out all 0.3s;
}

.site-footer .widget_nav_menu a:hover:before {
  width: 16px;
}

.site-footer .widget_nav_menu a:hover {
  padding-left: 25px;
  color: #04633b !important;
}

.footer_copyright .main_container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer_copyright .copyrighy_info {
  flex-basis: 50%;
}

.footer_copyright p {
  margin: 0;
  color: #333945;
  font-size: 14px;
  /*font-family: 'open_sanssemibold';*/
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
}

.footer_copyright .social_icon {
  flex-basis: 50%;
}

.footer_copyright p {
  margin: 0;
}

.site-footer .wrap {
  padding-bottom: 0;
}

.footer_copyright {
  padding: 24px 0;
  border-top: 1px solid #bdbec2;
  padding-top: 25px;
  position: relative;
}

.site-footer .widget-area .widget-column section#text-4 p {
  padding: 0;
  margin: 0;
}

.site-footer .widget-area .widget-column section#text-4 .textwidget {
  border: 3px solid #005d34;
  padding: 10px;
  margin-top: -16px;
  padding-top: 25px;
}

.site-footer .widget-area .widget-column section#text-4 h2.widget-title {
  display: inline-block;
  background: #f8f8f8;
  padding: 0 10px;
}

.site-footer .widget-area .widget-column section#text-4 {
  text-align: center;
}

.site-footer .widget-area .widget-column section#custom_html-2 {
  margin: 0;
  padding: 0;
  display: inline-block;
  width: 100%;
  text-align: right;
}

section#custom_html-2 a {
  background: #005d34;
  color: #fff;
  font-size: 14px;
  padding: 14px 29px;
  position: relative;
  padding-left: 50px;
  box-shadow: none;
  transition: 0.3s;
  border: 2px solid transparent;
  display: inline-block;
}

section#custom_html-2 a:hover {
  color: #fff;
  border-color: #000;
  background: #000;
}

section#custom_html-2 a:before {
  content: '';
  background: url(assets/images/multi-icon.png) no-repeat;
  position: absolute;
  width: 28px;
  height: 28px;
  left: 25px;
  top: 15px;
  background-position: -135px -11px;
}

.social_icon ul li {
  display: inline-block;
}

.social_icon ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline-block;
  text-align: right;
  width: 100%;
}

.site-footer:before {
  content: '';
  background: url(assets/images/dot_repeater_medium.png);
  position: absolute;
  width: 240px;
  height: 240px;
  transform: rotate(30deg);
  bottom: 0;
  left: -60px;
}

#text-2 img {
  position: relative;
}

.site-footer .widget_text {
  margin: 0;
  padding: 0;
  list-style: none;
}

.site-footer .widget_text ul {
  margin-top: 0;
  margin: 0;
  padding: 0;
  list-style: none;
}

.footer_copyright .social_icon ul li {
  border: 1px solid #000;
  height: 30px;
  width: 30px;
  text-align: center;
  border-radius: 50%;
  line-height: 30px;
  padding: 0;
  margin: 0 3px;
}

.footer_copyright .social_icon ul li:nth-child(2) {
  line-height: 28px;
}

.site-footer .widget-area .widget-column section#custom_html-2 {
  margin-top: 20px;
}

.footer_copyright .social_icon ul li a {
  box-shadow: none;
  text-decoration: none !important;
}

/*header section*/

#masthead .wrap {
  max-width: 100%;
  overflow: visible;
}

.navigation-top .main-navigation {
  width: calc(100% - 155px);
  float: right;
  display: inline-block;
  clear: none;
  text-align: right;
}

.navigation-top .custom-logo-link {
  //width: 200px;
  min-width: 100px;
  padding: 0;
  float: left;
  //padding-top: 12px;
}

.home_slider {
  /*padding-left: 100px;*/
}

.main-navigation ul.menu {
  text-align: right;
}

.site-header {
  //background-color: transparent;
  background-color: #ffffff;
}

.navigation-top {
  //background: transparent;
  background-color: #ffffff;
  border-top: 0;
  border-bottom: 0;
}

.site-header .main-navigation a {
  font-size: 14px;
  padding: 1em 2.25em;
}

.site-header .main-navigation .menu > li a {
  text-decoration: none;
}

.site-header .main-navigation .menu > li a:hover {
 color: #3B8E3F;
}

.site-header .main-navigation .menu > li.current-menu-item a {
  position: relative;
}

.site-header .main-navigation .menu > li.current-menu-item a:hover {
  text-decoration: none;
  color: #3B8E3F;
}

.site-header .main-navigation .menu > li.current-menu-item a:before {
  content: '';
  position: absolute;
  left: 0;
  bottom: -3px;
  right: 0;
  height: 8px;
  width: 8px;
  background: #3B8E3F;
  margin: 0 auto;
  border-radius: 8px;
}

.custom-header > .site-branding {
  margin-bottom: 0 !important;
  padding: 0;
}

.site-header .main-navigation .menu > li {
  //height: 70px;
  //padding-left: 12px;
  //padding-top: 11px;
  //padding-right: 15px;
  padding: 5px 0 5px 12px;
}

.site-header .main-navigation .menu > li a {
  font-size: 14px;
  padding: 0;
  padding: 5px 0;
  padding-left: 10px;
  padding-right: 10px;
  text-transform: capitalize;
  /*font-family: 'open_sanssemibold';*/
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
}

.site-header .main-navigation li.menu-item-1127 a {
  background: #43A047;
  color: #fff;
  padding: 8px 20px;
  //padding-left: 40px;
  //padding-right: 45px;
  //border-top-left-radius: 3px;
  //border-bottom-left-radius: 3px;
  border-radius: 3px;
  position: relative;
  //transition: 0.3s;
}

.site-header .main-navigation li.menu-item-1127 a:hover {
  background: #3B8E3F;
  text-decoration: none;
  color: #fff;
}

.site-header .main-navigation li.menu-item-1127 {
  padding-right: 0;
}

//.site-header .main-navigation li.menu-item-1127 a:after {
//  content: '';
//  /* background: url(assets/images/user-icon.png) no-repeat; */
//  background: url(assets/images/multi-icon.png) no-repeat;
//  position: absolute;
//  left: 16px;
//  width: 24px;
//  height: 24px;
//  top: 14px;
//  background-position: -92px -11px;
//}

.site-header .navigation-top {
  padding-left: 100px;
}

.custom-header {
  margin: 0 !important;
}

.main-navigation ul li.menu-item-has-children:before,
.main-navigation ul li.menu-item-has-children:after,
.main-navigation ul li.page_item_has_children:before,
.main-navigation ul li.page_item_has_children:after {
  right: 4em;
}

/*Homepage slider*/

.home .flexslider {
  border: 0;
  overflow: visible !important;
}

.panel-content .wrap {
  padding-top: 0;
}

.flex-control-paging li a:hover {
  box-shadow: none;
}

/*FAQ page*/

.page_faq .right-cate-faq {
  //width: calc(100% - 300px);
  width: 100%;
  //float: left;
  .col-sm-9 {
    padding: 0;
    width: 100%;
  }
}

.page_faq .left-cate-faq {
  width: 100%;
  overflow: hidden;
  display: block;
  margin-bottom: 20px;
}

.page_faq .left-cate-faq .col-sm-3 {
  min-height: 0;
  padding: 0 10px 0 10px;
}

.page_faq .left-cate-faq {
  a {
    &:hover {
      text-decoration: none;
    }
  }
}

@media (min-width: 1025px) {
  .page_faq .left-cate-faq .col-sm-3 {
    width: 25%;
  }
}
@media (max-width: 1024px) {
  .page_faq .left-cate-faq .col-sm-3 {
    width: 33%;
  }
}
@media (max-width: 468px) {
  .page_faq .left-cate-faq .col-sm-3 {
    width: 100%;
  }
}


.left-cate-faq h3.evdpl-faq-term-title:before {
  visibility: hidden;
  height: 0;
  clear: both;
}

.left-cate-faq a {
  text-decoration: none;
  box-shadow: none !important;
}

.left-cate-faq .evdpl-faq-term-title {
  margin: 0;
}

.left-cate-faq a {
  text-decoration: none;
  box-shadow: none !important;
  display: inline-block;
  width: 100%;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  align-items: center;
  text-align: center;
  text-transform: capitalize;
  color: #333333;
}

.left-cate-faq .evdpl-faq-term-title {
  margin: 0;
  padding: 17px 0;
  border-bottom: 1px solid #E0E0E0;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  align-items: center;
  text-align: center;
  text-transform: capitalize;
  color: #333333;
}

.page_faq .left-cate-faq > [class*="col-"] {
  border-bottom: 0;
}

.left-cate-faq .evdpl-faq-term-title.active > a {
  color: #333333;
}

.left-cate-faq {
  .evdpl-faq-term-title.active {
    //background: #005d34;
    border: solid 0 #43A047;
    border-bottom-width: 2px;
  }
}

.left-cate-faq .evdpl-faq-term-title.active i {
  color: #fff;
}

.left-cate-faq .active .evdpl-faq-term-title {
  background: #f9f9f9;
}

.left-cate-faq .active .evdpl-faq-term-title a {
  color: #005d34;
}

.page_faq .right-cate-faq {
  padding-left: 0;
}

.page_faq .left-cate-faq > [class*="col-"] .col-sm-12 h3:hover a:before {
  width: 10px;
}

.page_faq .left-cate-faq > [class*="col-"] .col-sm-12 h3 {
  border-bottom: 2px solid #f7f7f7;
}

.page_faq .left-cate-faq > [class*="col-"] .col-sm-12 h3 > a:before {
  content: "";
  background-color: #05643c;
  height: 2px;
  width: 0px;
  left: 0;
  top: 50%;
  position: absolute;
  transition: ease-in-out all 0.3s;
  visibility: visible;
}

.left-cate-faq .evdpl-faq-term-title.active a:before {
  background-color: #fff !important;
}

.page_faq .left-cate-faq > [class*="col-"] .col-sm-12 h3 a {
  position: relative;
  padding-left: 0;
  margin-left: 50px;
  transition: ease-in-out all 0.3s !important;
}

.page_faq .left-cate-faq > [class*="col-"] .col-sm-12 h3:hover a {
  padding-left: 20px !important;
}

.page_faq .evdpl-faq-accordion-title.ui-state-default {
  //background: #f7f7f7;
  //border-color: #e4e4e4;
  //border-radius: 0;
  //color: #333945;
  //font-size: 18px;
  text-align: left;
  background: #FFFFFF;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  border-color: #fff;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 24px !important;
  line-height: 33px;
  color: #333333;
}

.page_faq .evdpl-faq-accordion-wrap.ui-accordion .ui-accordion-icons {
  padding: 24px 50px 18px;
  padding-left: 25px;
  //font-family: 'futura_ltregular';
  letter-spacing: 1px;
  margin-bottom: 20px;
}

.page_faq .evdpl-faq-accordion-wrap.ui-accordion .ui-accordion-content p {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: #515151;

}

.page_faq .evdpl-faq-accordion-wrap.ui-accordion .ui-accordion-content {
  text-align: left;
  padding: 0 25px 25px 25px;
  /*font-family: 'open_sansregular';*/
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  margin-bottom: 15px !important;
  border-color: #fff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.page_faq .evdpl-faq-accordion-wrap.ui-accordion .ui-accordion-header-active {
  border-color: #fff;
  background-color: #FFFFFF !important;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px 10px 0px 0px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  color: #3B8E3F;
  position: relative;
  z-index: 0;
}
.page_faq .evdpl-faq-accordion-wrap.ui-accordion .ui-accordion-content-active {
  border-radius: 0 0 10px 10px;
  position: relative;
  z-index: 20;
  margin-bottom: 20px;
}

.page_faq .evdpl-faq-accordion-wrap.ui-accordion .ui-accordion-content ul,
.page_faq .evdpl-faq-accordion-wrap.ui-accordion .ui-accordion-content ol {
  padding-left: 20px;
}

.page_faq .evdpl-faq-accordion-wrap.ui-accordion .ui-accordion-content ul li,
.page_faq .evdpl-faq-accordion-wrap.ui-accordion .ui-accordion-content ol li {
  margin: 8px 0;
}

.page_description h3 {
  font-size: 28px;
  color: #333945;
  font-family: 'futura_ltregular';
  font-weight: 600;
}
.page_description h2 {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 65px;
  color: #333333;
}
.page_description p {
  font-size: 16px;
  color: #818181;
}

.page_description {
  text-align: left;
  max-width: 60%;
}

body.page:not(.twentyseventeen-front-page) .entry-title {
  font-size: 28px;
  color: #333945;
  font-family: 'futura_ltregular';
  text-transform: capitalize;
  margin-top: 10px;
}

.page-id-1149 .entry-header {
  display: none;
}

.page:not(.home) #content {
  padding-bottom: 0;
  margin: 0;
  padding: 0;
}

.panel-content .entry-header {
  margin-bottom: 2em;
}

/*Chart plans*/

.comparison .text_light {
  color: #061d08;
  font-size: 16px;
  /*font-family: 'open_sanssemibold';*/
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
}

.comparison .text-year {
  margin: 0;
  padding: 0;
  font-size: 16px;
  color: #061d08;
  /*font-family: 'open_sanssemibold';*/
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  margin-bottom: 25px !important;
  margin-top: 10px;
  text-align: center;
}

.comparison {
  margin: 0 auto;
  font: 13px/1.4 "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-align: center;
  padding: 10px;
  max-width: 1020px;
  width: 100%;
}

.comparison table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border-bottom: 1px solid #CCC;
}

.comparison td,
.comparison th {
  border-right: 1px solid transparent;
  empty-cells: show;
  padding: 18px 10px;
}

.compare-heading {
  font-size: 18px;
  font-weight: bold !important;
  border-bottom: 0 !important;
  padding-top: 10px !important;
}

.comparison tbody tr:nth-child(odd) {
  display: none;
}

.comparison tfoot tr th {
  padding-left: 25px;
  vertical-align: top;
  padding-top: 20px;
}

.plans_chart .comparison td img {
  max-width: 13px;
}

.comparison tfoot tr th:not(.price-info) span {
  font-weight: 800;
  color: #061d08;
  font-size: 26px;
  font-family: 'futura_ltregular';
  font-weight: 600;
}

.comparison .compare-row {
  background: #a5d6a7;
  border-color: transparent;
  border-bottom: 0;
}

.comparison .tickblue {
  color: #0078C1;
}

.comparison .tickgreen {
  color: #009E2C;
}

.comparison th {
  font-weight: normal;
  padding: 0;
  border-bottom: 1px solid #eee;
}

.comparison tr td:first-child {
  text-align: left;
  color: #061d08;
  font-size: 16px;
  /*font-family: 'open_sanssemibold';*/
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  padding-left: 25px;
  width: 650px;
}

.comparison .qbse,
.comparison .qbo,
.comparison .tl {
  color: #FFF;
  padding: 10px;
  font-size: 13px;
  border-right: 1px solid #CCC;
  border-bottom: 0;
}

.comparison .tl2 {
  border-right: 0;
}

.comparison .qbse {
  background: #0078C1;
  border-top-left-radius: 3px;
  border-left: 0px;
}

.comparison .qbo {
  background: #009E2C;
  border-top-right-radius: 3px;
  border-right: 0px;
}

.comparison .price-info {
  padding: 15px 15px 15px 15px;
  vertical-align: top;
  text-align: center;
}

.comparison .price-was {
  color: #999;
  text-decoration: line-through;
}

.comparison .price-now,
.comparison .price-now span {
  color: #ff5406;
}

.comparison .price-now span {
  font-size: 32px;
}

.comparison .price-small {
  font-size: 18px !important;
  position: relative;
  top: -11px;
  left: 2px;
}

.comparison .price-buy:hover {
  background: #000;
  color: #fff;
}

.comparison .price-buy {
  background: #005d34;
  padding: 10px 20px;
  font-size: 24px;
  display: inline-block;
  color: #FFF;
  text-decoration: none;
  border-radius: 0;
  text-transform: capitalize;
  margin: 5px 0 10px 0;
  /*font-family: 'open_sansbold'*/
  font-family: 'open_sansbold';
  font-weight: 900;
  min-width: 190px;
  border: 1px solid;
  text-align: center;
  margin: 0;
  transition: 0.3s;
}

.comparison .price-buy.btn_border {
  background: transparent;
  color: #061d08;
  border-color: #005d34;
}

.comparison .price-buy.btn_border:hover {
  background: #005d34;
  border-color: #005d34;
  color: #fff;
}

.comparison .price-try {
  font-size: 12px;
}

.comparison .price-try a {
  color: #202020;
}

.comparison a {
  text-decoration: none !important;
  box-shadow: none !important;
}

.price-info p {
  margin: 0;
  text-align: center;
}

.comparison thead tr th.price-info {
  background: #a5d6a7;
  vertical-align: middle;
  height: 100px;
}

.comparison thead tr th.price-info .price-title {
  color: #061d08;
  font-size: 26px;
  font-family: 'futura_ltregular';
  font-weight: 600;
  text-align: center;
}

.radius_top_right {
  border-top-right-radius: 15px;
}

.comparison .compare-row > td:first-child {
  background: #f2f2f2;
}

.comparison table {
  box-shadow: 0px 13px 45px 0px rgba(0, 0, 0, 0.08);
  border-bottom: 0;
  border-radius: 15px;
}

.plans_chart .vc_column_container > .vc_column-inner {
  padding: 0;
}

.page-template-blog .entry-header {
  display: none;
}

@media (max-width: 767px) {
  .comparison td:first-child,
  .comparison th:first-child {
    display: none;
  }
  .comparison tbody tr:nth-child(odd) {
    display: table-row;
    background: #F7F7F7;
  }
  .comparison .row {
    background: #FFF;
  }
  .comparison td,
  .comparison th {
    border: 1px solid #9a9a91;
    border-left: 0;
  }
  .price-info {
    border-top: 0 !important;
  }
}

@media (max-width: 639px) {
  .comparison .price-buy {
    padding: 5px 10px;
  }
  .comparison td,
  .comparison th {
    padding: 10px 5px;
  }
  .comparison .hide-mobile {
    display: none;
  }
  .comparison .price-now span {
    font-size: 16px;
  }
  .comparison .price-small {
    font-size: 16px !important;
    top: 0;
    left: 0;
  }
  .comparison .qbse,
  .comparison .qbo {
    font-size: 12px;
    padding: 10px 5px;
  }
  .comparison .price-buy {
    margin-top: 10px;
  }
  .compare-heading {
    font-size: 13px;
  }
}

/*Tools page*/

.page_info_inner {
  text-align: left;
  max-width: 80%;
  margin: 0;
}

.page_info_inner h1 {
  font-size: 36px;
  margin: 0;
  font-family: 'futura_ltbold';
  margin-bottom: 15px;
}

.page_info_inner p {
  font-size: 18px;
  color: #333945;
}

.tab_box ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: flex-end;
  //border-bottom: 2px solid #cacaca;
}

.tab_box ul li {
  width: 25%;
  text-align: center;
  padding-right: 7px;
  margin-right: 8px;
  margin-bottom: -2px !important;
  border: 0 solid #E0E0E0;
  border-bottom-width: 1px;
  &:hover {
    border: 0 solid #43A047;
    border-bottom-width: 1px;
  }
}
.tab_box ul li.active {
  border-bottom-width: 0px;
}

.tab_box a {
  text-decoration: none !important;
  box-shadow: none !important;
  display: inline-block;
  padding: 20px 10px;
  width: 100%;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  align-items: center;
  text-align: center;
  color: #333333;
}

.tab_box li.active a {
  border: 0 solid #43A047;
  border-radius: unset;
  border-bottom-width: 2px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  align-items: center;
  text-align: center;
  color: #333333;
}

.tab_box a:hover {
  //background: #e5e5e5;
  //  border: 0 solid #43A047;
    //border-bottom-width: 1px;
}

.tab_box ul li:last-child {
  padding-right: 0;
}

.post_grid .vc_col-sm-4 {
  width: 44%;
}

.post_grid .vc_col-sm-8 {
  width: 56%;
}

.page_boxes_points ul {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: left;
}

.page_boxes_points ul li {
  font-size: 18px;
  color: #333945;
  margin-bottom: 20px;
  position: relative;
  padding-left: 40px;
}

.page_boxes_points ul li:before {
  content: '';
  background: url(assets/images/post_listing_icon.png) no-repeat;
  width: 24px;
  height: 24px;
  position: absolute;
  background-position: center;
  left: 0;
  top: 0;
  background-size: 100%;
}

.post_content .main_title {
  text-align: left;
  margin-bottom: 20px;
}

.post_content .main_title ul li strong {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #515151;
}

.page_boxes_points {
  border-top: 1px solid #bababa;
  padding-top: 40px;
  margin-top: 40px;
}

.post_grid {
  margin-top: 40px;
}

.tab_content {
  margin-top: 20px;
}

.tools_accordian .evdpl-featured_tool-accordion-title.ui-state-default {
  //background: #f7f7f7;
  //color: #333945;
  border-color: #ffffff;

  border-radius: 0;
}

.tools_accordian .ui-accordion .ui-accordion-header {
  padding: 18px 10px 18px 10px;
  margin: 0;
  text-align: left;
}

.tools_accordian .evdpl-featured_tool-accordion-wrap .ui-icon-triangle-1-e,
.page_faq .evdpl-faq-accordion-wrap .ui-icon-triangle-1-e {
  background-image: url(assets/images/group_plus.png) !important;
  width: 14px;
  height: 14px;
  position: absolute;
  top: 51%;
}

.tools_accordian .evdpl-featured_tool-accordion-wrap .ui-icon-triangle-1-s,
.page_faq  .evdpl-faq-accordion-wrap .ui-icon-triangle-1-s {
  background-image: url(assets/images/vector.png) !important;
  width: 14px;
  height: 2px;
  top: 51%;
  position: absolute;
}

.tools_accordian .evdpl-featured_tool-accordion-wrap.ui-accordion .ui-accordion-header .ui-accordion-header-icon {
  top: 40%;
}

.tools_accordian .evdpl-featured_tool-accordion-wrap.ui-accordion .ui-accordion-content {
  border-radius: 0;
  border-color: #ffffff;
  margin-bottom: -1px;
  padding: 0 40px 40px 25px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  position: relative;
}

.tools_accordian .evdpl-featured_tool-accordion-wrap.ui-accordion .ui-accordion-icons {
  text-transform: capitalize;
  margin-bottom: -1px;
  /*font-family: 'open_sanssemibold';*/
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  padding-left: 45px;
}

.tools_accordian .evdpl-featured_tool-accordion-wrap.ui-accordion .ui-accordion-content p {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: #515151;
}
.tools_accordian .evdpl-featured_tool-accordion-wrap.ui-accordion .ui-accordion-content p span{
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: #515151;
}

.tools_accordian .evdpl-featured_tool-accordion-wrap .ui-accordion-header-active,
.tools_accordian .evdpl-featured_tool-accordion-title.ui-state-default {
  //background: #005d34 !important;
  background: unset !important;
  font-family: 'Open Sans' !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 24px !important;
  line-height: 33px !important;
  color: #3B8E3F !important;

}

.tools_accordian .evdpl-featured_tool-accordion-title.ui-state-default {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px !important;
  color: #333333 !important;
}
.tools_accordian .evdpl-featured_tool-accordion-wrap .ui-accordion-header-active {
  color: #3B8E3F !important;
  margin-bottom: -1px !important;
  //box-shadow: unset;
  z-index: 0;
}



.evdpl-featured_tool-accordion-content .col-sm-4 img {
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.1);
}

.evdpl-featured_tool-accordion-content .col-sm-4 {
  width: 45%;
  float: left;
}

.evdpl-featured_tool-accordion-content .col-sm-8 {
  width: 55%;
  text-align: left;
  float: left;
  padding-right: 20px;
}

.gray-plan {
  display: flex;
  background: #eaeaea;
  align-items: center;
  justify-content: space-between;
  border-bottom: 4px solid #c9c9c9;
  margin-top: 50px;
}

.gray-plan .col-sm-4 {
  flex-basis: 33.33%;
  text-align: center;
  font-size: 18px;
  color: #333945;
  /*font-family: 'open_sanssemibold';*/
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  padding: 31px 10px;
}

.gray-plan .col-sm-4 span {
  position: relative;
  padding-left: 25px;
}

.gray-plan .col-sm-4 span:before {
  content: '';
  position: absolute;
  left: 0;
  height: 17px;
  width: 17px;
  top: 4px;
  border-radius: 50%;
  background: #005d34;
}

.gray-plan .col-sm-4:nth-child(2) span:before {
  background: #808080;
}

.gray-plan .col-sm-4:nth-child(3) span:before {
  background: #dccf04;
}

.tools_accordian .main_title {
  text-align: left;
  margin-bottom: 50px;
}

.evdpl-featured_tool-term-title {
  display: none;
}

.tools_accordian {
  margin-top: 100px;
}

.section_order_printing.accordian_page {
  margin-top: 0 !important;
  padding-bottom: 40px;
  padding-top: 60px !important;
  border: 1px solid rgba(224, 224, 224, .7);
  border-radius: 10px;
  margin-bottom: 50px !important;
}

body.page-id-1305 .wrap,
body.page-id-1411 .wrap,
body.page-id-1413 .wrap,
body.page-id-1415 .wrap,
body.page-id-1446 .wrap {
  width: 100%;
  max-width: 100% !important;
  margin: 0;
  overflow: hidden;
}

.page-id-1305 header.entry-header,
.page-id-1411 header.entry-header,
.page-id-1413 header.entry-header,
.page-id-1415 header.entry-header,
.page-id-1446 header.entry-header {
  display: none;
}

.page.page-id-1305 #content,
.page.page-id-1411 #content,
.page.page-id-1413 #content,
.page.page-id-1415 #content,
.page.page-id-1416 #content {
  margin-top: 0;
}

.tab_content {
  margin-top: 30px !important;
}

.tools_accordian {
  margin-top: 40px !important;
}

.evdpl-featured_tool-accordion-content .col-sm-4.image-Left {
  float: right;
}

.evdpl-featured_tool-accordion-content .col-sm-8.image-Right {
  float: left;
  padding-right: 0;
  padding-left: 20px;
}

.post_content .main_title p {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: #515151;
}

.post_content h4.test-family-chart {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  color: #333333;
}

/*Contact form*/

.contact_form {
  width: 100%;
  float: left;
  max-width: 800px;
  margin: 0 auto;
  float: none;
  display: inline-block;
}

.contact_form .filed_half {
  display: inline-block;
  width: 100%;
}

.contact_form .filed_half .field {
  width: 50%;
  float: left;
  margin-bottom: 15px;
  padding: 0 15px;
}

.contact_form .field label {
  text-align: left;
  font-weight: normal;
  font-family: 'futura_ltregular';
}

.contact_form .wpcf7-form-control-wrap {
  display: inline-block;
  width: 100%;
  margin-top: 4px;
}

.contact_form .field {
  padding: 0 15px;
}

.contact_form textarea {
  min-width: 100%;
  min-height: 100px;
  max-height: 140px;
  height: 100% !important;
  width: 100% !important;
}

.contact_form .buttons button {
  background: #005d34;
  color: #fff;
  font-size: 18px;
  /*font-family: 'open_sanssemibold';*/
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  padding: 10px 40px;
  display: inline-block;
  border: 2px solid transparent;
  text-decoration: none;
  box-shadow: none !important;
  transition: 0.3s;
}

.contact_form .buttons button:hover {
  border-color: #005d34;
  background: transparent;
  color: #005d34;
}

.comparison {
  border: 1px solid #000;
  padding: 0;
  border-radius: 18px;
}

.vc_btn3.vc_btn3-color-juicy-pink,
.vc_btn3.vc_btn3-color-juicy-pink.vc_btn3-style-flat {
  background: #005d34 !important;
  color: #fff !important;
  font-size: 16px;
  /*font-family: 'open_sanssemibold';*/
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  padding: 6px 20px;
  display: inline-block;
  border: 2px solid transparent !important;
  text-decoration: none;
  box-shadow: none !important;
  transition: 0.3s;
}

.vc_btn3.vc_btn3-color-juicy-pink,
.vc_btn3.vc_btn3-color-juicy-pink.vc_btn3-style-flat:hover {
  background: transparent !important;
  border-color: #005d34 !important;
  color: #005d34 !important;
}

.vc_grid-pagination .vc_grid-pagination-list.vc_grid-pagination-color-green > li > a,
.vc_grid-pagination .vc_grid-pagination-list.vc_grid-pagination-color-green > li > span {
  background-color: #015d34 !important;
  border-color: #015d34 !important;
  color: #fff !important;
}

.vc_grid-pagination .vc_grid-pagination-list.vc_grid-pagination-color-green > li > a,
.vc_grid-pagination .vc_grid-pagination-list.vc_grid-pagination-color-green > li > span {
  background-color: #015d34 !important;
  border-color: #015d34 !important;
  color: #fff !important;
  min-height: 30px !important;
  min-width: 30px !important;
  line-height: 30px;
  font-size: 16px;
}

.vc_grid-pagination a {
  text-decoration: none !important;
  box-shadow: none !important;
}

body .vc_grid-pagination .vc_grid-pagination-list.vc_grid-pagination-color-green > li > a,
body .vc_grid-pagination .vc_grid-pagination-list.vc_grid-pagination-color-green > li > span {
  background-color: #005d34 !important;
  border-color: #005d34 !important;
  color: #fff !important;
}

/*About us page*/

.page_about_us {
  position: relative;
  min-height: 450px;
}

.page_about_us:before {
  content: '';
  display: block !important;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
}

.about_description {
  max-width: 50%;
  text-align: left;
}

.about_description h1 {
  color: #fff;
  font-size: 36px;
  font-family: 'futura_ltbold';
  line-height: 40px;
}

.about_description p {
  margin: 0;
  color: #fff;
  margin-bottom: 10px;
}

.page-id-1446 .section_profile {
  padding-bottom: 40px;
}

.page.page-id-1446 div#content {
  margin-top: 0;
}

/*Breadcumb*/

.breadcrumbs {
  //background: url(assets/images/breadcumb_bg.png);
  height: 33px;
  margin-bottom: 23px;
  line-height: 33px;
}

.breadcrumbs > .main_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.breadcrumbs .left-breadcrumbs {
  font-size: 24px;
  color: #333333;
  font-family: 'futura_ltbold';
}

.right-breadcrumbs span,
.right-breadcrumbs a {
  font-size: 18px;
  font-family: 'futura_ltregular';
  color: #ffffff;
}

.breadcrumbs .right-breadcrumbs {
  flex-basis: 50%;
  text-align: right;
}

.breadcrumbs .left-breadcrumbs {
  flex-basis: 50%;
  text-align: left;
}

.breadcrumbs .breadcrumbs-child {
  color: #fff;
}

.page-id-1192 .entry-header,
.page-id-1365 .entry-header,
.page-id-1632 .entry-header,
.page-id-1636 .entry-header {
  display: none;
}

body .vc_custom_1419250758402 {
  background-color: #005d34 !important;
}

.vc_pageable-load-more-btn a:hover {
  border-color: #005d34 !important;
  background-color: transparent !important;
  color: #005d34 !important;
}

.vc_pageable-load-more-btn a {
  background: #005d34 !important;
  text-decoration: none !important;
  box-shadow: none !important;
  border: 2px solid transparent;
  font-weight: 600;
}

//.comments-area {
//  display: none;
//}

.single-post .single-featured-image-header {
  max-height: 600px;
}

.single-post .wpb_wrapper ul li {
  margin: 8px 0;
}

.single-post .type-post .wpb_wrapper ul,
.single-post .type-post .wpb_wrapper ol {
  padding-left: 20px;
}

/*14-5-19*/

.treemily-banners .page_info_inner h1 {
  color: #fff;
}

.treemily-banners .page_info_inner p {
  color: #fff;
}

.treemily-banners {
  height: 460px;
}

.post-1632 .entry-content,
.post-1636 .entry-content {
  text-align: left;
}

.post-1632 ul li,
.post-1636 ol li {
  line-height: 32px;
  padding: 6px 0;
}

.post-1632 ul,
.post-1636 ol {
  padding: 10px 0;
}

.page_faq .evdpl-faq-accordion-wrap.ui-accordion .ui-accordion-header .ui-accordion-header-icon {
  top: 60%;
  right: 3px;
  transform: translate(-50%, -65%);
  position: absolute;
}

.page_faq .evdpl-faq-accordion-wrap .ui-icon-triangle-1-s {
  -webkit-transform: rotate(180deg) !important;
  -ms-transform: rotate(180deg) !important;
  transform: rotate(180deg) !important;
}

.page_faq .evdpl-faq-accordion-wrap .ui-icon-triangle-1-s {
  -webkit-transform: rotate(180deg) !important;
  -ms-transform: rotate(180deg) !important;
  transform: rotate(180deg) !important;
  right: 10px !important;
  top: 50% !important;
}

.page_faq {
 .accordion-content {
    a {
      text-decoration: none;
      box-shadow: unset;
      font-family: 'Open Sans' !important;
      font-style: normal !important;
      font-weight: 400 !important;
      font-size: 18px !important;
      line-height: 25px !important;
      color: #515151 !important;
      &:hover {
        text-decoration: underline;
      }
    }

 }
}

.main_offer_block {
  clear: both;
  width: 100%;
  display: inline-block;
  margin-bottom: 2rem !important;
  margin-top: 20px;
}

.main_offer_block ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.main_offer_block ul li {
  flex-basis: 200px;
  max-width: 200px;
  width: 100%;
  list-style: none;
  //margin-right: 3%;
}
.main_offer_block ul li:last-child {
  margin-left: 20px;
}

@media (min-width: 992px) and (max-width: 1024px) {
  .main_offer_block ul li:last-child {
    margin-left: 3%;
  }
}
@media (min-width: 481px) and (max-width: 991px) {
  .main_offer_block ul li:last-child {
    margin-left: 2%;
  }
}

//.plan_info:before {
//  content: '';
//  width: 0;
//  height: 0;
//  border-top: 100px solid #005d34;
//  border-right: 120px solid transparent;
//  position: absolute;
//  top: 0;
//  left: 0;
//}

.plan_info {
  position: relative;
  margin: 12px 0 0 0;
}

//.plan_info .plan_title h4 {
//  color: #fff;
//}

.plan_info .plan_title {
  position: absolute;
  left: 33%;
  //right: 0%;
  top: 15%;
  //bottom: 43.33%;
}

.plan_info .plan_title h4,
.plan_info .plan_title p{
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #333333;
  margin: 0;
}

.plan_info_title {
  position: absolute;
  left: 33%;
  right: 0%;
  top: 45%;
  bottom: 15%;
}

.plan_icon {
  position: absolute;
  left: 9.5%;
  right: 73%;
  top: 20%;
  bottom: 21.67%;
  //background: #43A047;
}

.plan_shape {
  //min-height: 100px;
  //border: 1px solid #c8c8c8;
  width: 200px;
  height: 60px;
  background: #FFFFFF;
  border: 1px solid #E0E0E0;
  border-radius: 3px;
}

.main_offer_block ul li:last-child {
  margin-right: 0;
}

.inclided_wrap .plan_info_title {
  padding-right: 22px;
}

.inclided_wrap .plan_icon {
  //padding-right: 30px;
}

/*SITE MAP*/
.box-wrap {
  border-radius: 4px;
  box-sizing: border-box;
  float: left;
  margin-bottom: 20px;
  padding: 15px 25px;
  width: 100%;
  box-shadow: 0 4px 5px #ddd;
  -moz-box-shadow: 0 4px 5px #ddd;
  -webkit-box-shadow: 0 1px 11px #ddd;
  margin-bottom: 30px;
  padding: 20px 20px;
}

.services-link .box-wrap h2, .services-link .box-wrap h3 {
  background-color: #3c3f46;
  padding: 11px 20px;
  text-transform: none;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 0;
}

.services-link ul li {
  float: left;
  list-style: outside none none;
  margin-right: 3%;
  padding: 5px 0 5px 22px;
  position: relative;
  width: 28%;
  transition: all ease-in-out 0.3s;
  margin-left: 20px;
  min-height: 60px;
}

.services-link ul li::before {
  background: #3c3f46;
  content: "";
  height: 7px;
  left: 2px;
  position: absolute;
  top: 13px;
  transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  width: 7px;
}

.services-link ul li::before {
  left: auto;
  margin-left: -18px;
}

.services-link ul li a {
  color: #000;
}

.services-link .box-wrap h3 a {
  color: #ffff;
}

.services-link ul li:hover a {
  color: #005d34 !important;
}

.services-link ul li:hover {
  padding-left: 30px;
}

.wrap-head ul.sub-menu {
  margin-top: 15px;
}

/*Mailing section Block*/
//.mailchimp-main-wrap {
//  position: relative;
//}
//
//.mailchimp-main-wrap .vc_column-inner {
//  padding: 15px !important;
//}
//
//.mailchimp-main-wrap .mailing-section-block:before {
//  content: "";
//  width: 100%;
//  height: 100%;
//  display: block;
//  top: 0;
//  left: 0;
//  position: absolute;
//}

//.footer-mailchimp {
//  padding: 0 15px;
//  margin: 0 !important;
//}
//
//.footer-mailchimp .mailing-section-block {
//  padding: 6rem;
//}
//
//.footer-mailchimp .main_title h3 {
//  color: #fff;
//  font-size: 24px;
//}
//
//.footer-mailchimp .main_title h3:before {
//  background: #005d34;
//}
//
//.footer-mailchimp h2, .footer-mailchimp p {
//  color: #fff;
//  text-shadow: 1px 1px #005d34;
//}
//
//.footer-mailchimp {
//  text-align: center;
//}
//
//.footer-mailchimp h2 {
//  font-size: 28px;
//  margin-bottom: 1rem;
//}

.footer-mailchimp .mainchimp-form {
  position: relative;
  max-width: 466px;
  //margin: 0 auto;

  &:before {
    content: '';
    background: url(assets/images/new-icons/arrow.png) no-repeat;
    position: absolute;
    right: 35px;
    top: 12px;
    width: 20px;
    height: 100%;
    background-position: top;
    z-index: 100;
  }
}

.footer-mailchimp .mainchimp-form input[type="submit"] {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #43A047;
  height: 45px;
  border-radius: 3px;
  width: 140px;
  text-align: left;
  padding-left: 40px;
}

.footer-mailchimp .mainchimp-form .wpcf7-form-control {
  height: 45px;
  border-radius: 3px;
  border: none;
}
//
//.mailing-section-block .vc_column-inner {
//  max-width: 1250px;
//  margin: 0 auto;
//}
//
//.mailchimp-main-wrap {
//  background-position: top !important
//}

.mailchimp-main-wrap .wpcf7-validation-errors {
  display: none !important;
}

.mailchimp-main-wrap .wpcf7-not-valid-tip {
  display: block;
  text-align: left;
  font-weight: 700;
}

.mailchimp-main-wrap .wpcf7-mail-sent-ok {
  max-width: 520px;
  margin: 0 auto;
  background: #398f14;
  position: relative;
  color: #fff;
}

//.mailchimp-main-wrap {
//  background-image: url("assets/images/mail_mobile_family.jpg") !important;
//  background-position: center !important;
//  background-repeat: no-repeat !important;
//  background-size: cover !important;
//}

.section_profile .profile_boxes_wrapper .vc_column-inner > .wpb_wrapper {
  margin-bottom: 40px;
}

//.mailchimp-main-wrap {
//  margin-left: 0 !important;
//  margin-right: 0 !important;
//}

.home .panel-content .wrap {
  padding-bottom: 0;
}

.site-footer {
  margin-top: 0;
}

.mailchimp-main-wrap div.wpcf7 .ajax-loader {
  position: absolute;
  top: 15px;
  right: -18px;
}

.entry-content .tool-button:hover {
  box-shadow: none;
  background-color: #fff !important;
  color: #015c34 !important;
  background-image: none;
  border: 2px solid #015c34 !important;
}

.entry-content .tool-button {
  font-weight: bold !important;
  border: 2px solid #fff !important;
}

.custom-logo-link img {
  width: 100%;
  //max-width: 133px !important;
}

.site-footer .footer-logo {
  max-width: 133px;
}

.category-list h4 {
  display: list-item;
  list-style-type: disc;
  list-style-position: inside;
  margin-top: 20px;
}

.custom_btn_wrapper .vc_general {
  //max-width: 420px;
  //width: 100%;
  //background-color: #c9d229;
  width: 180px;
  height: 45px;
  background: #43A047 !important;
  border-radius: 3px !important;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px !important;
  text-align: left !important;
  color: #FFFFFF !important;
  padding-left: 29px !important;
  &:before {
    content: '';
    background: url(assets/images/new-icons/arrow.png) no-repeat;
    position: absolute;
    right: 27px;
    top: 12px;
    width: 20px;
    height: 21px;
    background-position: top;
    z-index: 100;
  }
}

.home_product_slider {
  position: relative;
}

.home_product_slider.owl-theme .owl-nav {
  width: 100%;
  margin: 0;
  position: absolute;
  top: 50%;
  z-index: 999;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  transform: translate(0, -50%);
}

.home_product_slider.owl-theme .owl-nav [class*=owl-] {
  width: 40px;
  height: 80px;
  line-height: 70px;
  text-align: center;
  border-radius: 0;
  background: #005d34;
  margin: 0;
  font-size: 30px;
}

.evdpl-featured_tool-accordion-content .col-sm-12 {
  width: 100%;
  text-align: left;
  float: left;
  padding-right: 20px;
}

/*
Corona alert modal :: Start
=======================*/
#myCorona .modal-header .modal-title {
  margin: 0;
  padding: 0;
  display: inline-block;
  font-weight: 800;
}

#myCorona .modal-body {
  background: #048c50;
  min-height: 110px;
  display: flex;
  align-items: center;
}

#myCorona .modal-body p, #myCorona .modal-body {
  color: #fff;
}

#myCorona .modal-header .close {
  opacity: 1;
  margin-top: 2px;
}

#myCorona .modal-content {
  overflow: hidden;
}

div#myCorona {
  padding: 0 !important;
}

#myCorona .modal-dialog {
  margin-top: 0;
  vertical-align: middle;
  align-items: center;
  display: flex;
  margin-bottom: 0;
  height: 100vh;
}

/*
Corona alert modal :: End
=======================*/
.single-post .entry-header h1.entry-title {
  font-size: 26px;
}

.single-post .entry-content h2 {
  font-size: 22px;
}

nav button.menu-toggle:hover {
  background: none;
}

nav button.menu-toggle:focus {
  background: none;
}

nav button.dropdown-toggle:hover, nav button.dropdown-toggle:focus {
  background: none;
}

nav button.dropdown-toggle.toggled-on:hover, nav button.dropdown-toggle.toggled-on:focus {
  background: none;
}

.widget ul li {
  border-top: none;
  padding-top: 0;

  &.cat-item {
    padding-top: 7px;
  }

  a:hover {
    text-decoration: underline;
    box-shadow: none;
  }
}

.product-bottom-block {
  p {
    margin: 0;
    padding: 0;
  }
  a {
    text-decoration: none;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    align-items: center;
    text-transform: capitalize;
    color: #333333;
    box-shadow: none;
    &:hover {
      text-decoration: none;
      -webkit-box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.6) !important;
      box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.6) !important;
    }
  }
   h2 {
     margin: 0;
     padding: 0;
     a {
       text-decoration: none;
       font-family: 'Open Sans';
       font-style: normal;
       font-weight: 700;
       font-size: 48px;
       line-height: 65px;
       text-align: center;
       color: #333333;

       &:hover {
         -webkit-box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.6);
         box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.6);
       }
     }
   }
}

.site-content {
  padding: 5.5em 0 0;
}
.comment-form {
  label {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #515151;
  }
  #wp-comment-cookies-consent {
    width: 15px;
    height: 15px;
    border: 1px solid #43A047;
    vertical-align: middle;
  }
  .form-submit {
    #submit {
      background: #43A047;
      border-radius: 3px;
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 14px;
      align-items: center;
      text-align: center;
      color: #FFFFFF;
      width: 224px;
      height: 45px;
      background: #43A047;
    }
  }
}
.comment-form-author,
.comment-form-cookies-consent {
  width: 50%;
  float: left;
  padding-right: 4%;
}

.comment-form-cookies-consent,
.form-submit {
 margin-top: 20px;
}
.comment-form-email,
.form-submit {
  float: left;
  width: 50%;
}

.coopyright-div {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  text-transform: capitalize;
  color: #333333 !important;
}
.footer-quicklinks .footer-quicklinks-li a {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400 !important;
  font-size: 14px;
  line-height: 19px;
  align-items: center;
  text-transform: capitalize;
  color: #333333 !important;
}

.entry-content {
  a {
    box-shadow: none;
    &:hover {
      box-shadow: none;
    }
  }
}

.page_faq {
  a {
    text-decoration: none;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    color: #515151;
    &:hover {
      text-decoration: underline;
    }
  }
  p {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    color: #515151;
  }
}

.next-img {
  margin-left: 5px;
}
.breadcrumbs-top {
  margin-left: 75px;
  float: left;
  //border: solid 1px;
  height: 40px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #AAAAAA;
  padding-top: 10px;
  a {
    display: inline-block;
    padding: 0 !important;
    font-size: 12px !important;
    //color: #AAAAAA !important;
    font-weight: 400 !important;
  }

  .home-imh-bc {
    background: url(assets/images/new-icons/home_img.png) no-repeat;
    width: 15px;
    display: inline-block;
    height: 15px;
    padding: 0 !important;
    margin-bottom: -2px;
  }
}
@media (max-width: 1269px) {
  .breadcrumbs-top {
    display: none !important;
  }
}

.post-1149 {
  .page_description {
    max-width: 65%;
  }
}
@media (max-width: 1024px) {
  .page_description {
    max-width: 100% !important;
  }
}
.cards-container {
  img {
    border-radius: unset;
  }
}

.plan_info_title h4,
.plan_info_title p {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 19px !important;
  color: #43A047 !important;
  margin: 18px 0 0 0 !important;
}